import { Attendance, RegistrationResponse } from "@/panel/gema/types";
import { PanelRootState } from "@/panel/store/PanelRootState";
import { GetterTree } from "vuex";
import { NavigationId, NavigationItem, NavigationState } from "../types";

export const getters: GetterTree<NavigationState, PanelRootState> = {
  navbarItems(state: NavigationState): NavigationItem[] {
    if (
      state.registration &&
      state.registration.member.group === "G" &&
      state.registration.member.memberStatus === "G"
    )
      return state.navbarItems
        .filter((nav) => [NavigationId.RESOURCES].includes(nav.name))
        .sort((a, b) => {
          return a.rank - b.rank;
        });

    return state.navbarItems.sort((a, b) => {
      return a.rank - b.rank;
    });
  },

  attendingInPerson(state): boolean {
    return (
      (state?.registration &&
        (state.registration.registration?.attendanceType ===
          Attendance.IN_PERSON ||
          state.registration.registration?.attendanceType ===
            Attendance.BY_REPRESENTATIVE_IN_PERSON)) ||
      false
    );
  },

  registration(state): RegistrationResponse | undefined {
    return state.registration;
  },
};
