








































import { PollAnswer, PollVote } from "@/panel/polls/types";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Config } from "@/shared/config/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class PollImageAnswers extends Vue {
  @Prop({ required: true }) answers!: PollAnswer[];
  @Prop({ required: true }) votes!: PollVote[];
  @Prop({ default: 6 }) cols!: number;

  @Getter("config", { namespace: CONFIG_NAMESPACE })
  config!: Config;

  imageUrl(imagePath: string) {
    return this.config.mediaUploadBaseUrl + imagePath;
  }

  selected({ answerId }: PollAnswer): boolean {
    return this.votes.some(
      (pollVote) => pollVote.pollAnswer.answerId === answerId
    );
  }

  calcPercent(answer: PollAnswer): number {
    const total = this.answers.reduce((a, b) => {
      return a + b.voteCount;
    }, 0);

    if (total === 0) {
      return 0;
    }

    return Math.round((answer.voteCount / total) * 100);
  }
}
