

























































































































































































































































































































































import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { AppConfig } from "@/shared/config/types";
import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { RESOURCE_NAMESPACE } from "../store";
import { ResourceAction, ResourceGetter } from "../types";
import ResourceList from "./ResourceList.vue";
import ResourceInfoGema from "./ResourceInfoGema.vue";
import { isGema } from "@/shared/utils";

type ComponentTypes = "GemaQAPanel" | "QAPanel";

@Component({
  components: {
    ResourceList,
    ResourceInfoGema,
  },
})
export default class ResourcesPage extends Vue {
  @Getter("agenda", { namespace: CONFIG_NAMESPACE })
  agenda!: string;

  @Getter(ResourceGetter.ResourceCount, { namespace: RESOURCE_NAMESPACE })
  resourceCount!: number;

  @Action(ResourceAction.FetchResources, { namespace: RESOURCE_NAMESPACE })
  fetchResources!: (broadcastId?: string) => Promise<void>;

  @Getter("appConfig", { namespace: CONFIG_NAMESPACE })
  appConfig!: AppConfig;

  @Getter(`${CONFIG_NAMESPACE}/tenantName`) tenantName!: string;

  showAgenda = true;
  showResources = true;

  get componentName(): ComponentTypes {
    if (isGema(this.tenantName)) return "GemaQAPanel";
    return "QAPanel";
  }

  get linkPrefix(): string {
    return "https://static.gema-mgv.de/";
  }

  created() {
    this.fetchResources(this.appConfig.broadcastId);
  }
}
