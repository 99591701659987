import { UPMAN_MEDIA_BASE_URL } from "@/helpers/envConsts";
import { i18n } from "@/plugins/i18n/vue-i18n-plugin";
import { AppRootState } from "@/shared/@types/app";
import { apiService } from "@/shared/services/api-service";
import axios from "axios";
import Vue from "vue";
import { ActionTree } from "vuex";
import { AppConfig, Config, ConfigState, Options } from "../types";

export const actions: ActionTree<ConfigState, AppRootState> = {
  async setAppConfig(context, appConfig: AppConfig): Promise<AppConfig | null> {
    try {
      const broadcast = await apiService.getOrCreateBroadcast(
        appConfig.broadcastName
      );

      appConfig.broadcastId = broadcast.id;

      context.commit("agenda", broadcast.agenda);
      context.commit("appConfig", appConfig);
      Vue.$log.debug("Set AppConfig success", appConfig);
      return appConfig;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        const broadcast = await apiService.getBroadcastByName(
          appConfig.broadcastName
        );
        if (broadcast.id) {
          appConfig.broadcastId = broadcast.id;
          Vue.$log.debug("Set AppConfig success", appConfig);
          context.commit("appConfig", appConfig);
          return appConfig;
        }
      }
      Vue.$log.debug("Set AppConfig error", error);
      context.dispatch(
        "tracking/logError",
        {
          ea: "error",
          ec: "appConfig",
          ed: error,
        },
        { root: true }
      );
    }
    return null;
  },
  async fetchTenantConfig(
    { commit, dispatch },
    tenantId: string
  ): Promise<void> {
    try {
      const tenantPromise = apiService.getTenantById(tenantId);
      const featurePromise = apiService.getFeatures();

      const [tenantResponse, featureResponse] = await Promise.all([
        tenantPromise,
        featurePromise,
      ]);

      const config: Partial<Config> = {
        features: {
          enabled: featureResponse.features,
          available: [],
        },
        mediaUploadBaseUrl:
          tenantResponse.mediaUploadBaseUrl || UPMAN_MEDIA_BASE_URL,
      };

      Vue.$log.debug("TenantConfig success", config);
      commit("tenantConfig", config);
      commit("tenantName", tenantResponse.name);
    } catch (error) {
      Vue.$log.debug("TenantConfig error", error);
      dispatch(
        "tracking/logError",
        {
          ea: "error",
          ec: "tenantConfig",
          ed: error,
        },
        { root: true }
      );
    }
  },
  async fetchBroadcastConfig({ commit, dispatch, state }): Promise<void> {
    try {
      const appConfig: AppConfig | undefined = state.appConfig;
      const featureResponse = await apiService.getFeatures(
        appConfig?.broadcastId
      );

      const config: Partial<Config> = {
        features: {
          enabled: featureResponse.features,
          available: [],
        },
        mediaUploadBaseUrl:
          state.tenantConfig?.mediaUploadBaseUrl || UPMAN_MEDIA_BASE_URL,
      };

      Vue.$log.debug("BroadcastConfig success", config);
      commit("broadcastConfig", config);
    } catch (error) {
      Vue.$log.debug("BroadcastConfig error", error);
      dispatch(
        "tracking/logError",
        {
          ea: "error",
          ec: "broadcastConfig",
          ed: error,
        },
        { root: true }
      );
    }
  },
  async fetchSettings({ commit, dispatch }): Promise<void> {
    try {
      // const appConfig: AppConfig | undefined = state.appConfig;
      const settings = await apiService.fetchSettings();

      Vue.$log.debug("GeneralSettings success", settings);
      commit("settings", settings);
    } catch (error) {
      Vue.$log.debug("GeneralSettings error", error);
      dispatch(
        "tracking/logError",
        {
          ea: "error",
          ec: "generalSettings",
          ed: error,
        },
        { root: true }
      );
    }
  },
  setConfig({ commit }, config: Config): void {
    commit("config", config);
  },
  setOptions({ commit, state }, options: Options): void {
    i18n.locale = options.defaultLanguage ?? state.options.defaultLanguage;
    commit("options", options);
  },
};
