













import { Action } from "vuex-class";
import { Vue, Component, Prop } from "vue-property-decorator";
import { POLL_NAMESPACE } from "../store";
import { PollAction, PollType, PollVoteActionPayload } from "../types";
import { TRACKING_NAMESPACE } from "@/shared/tracking/store";

import { Poll, PollAnswer, PollStatus } from "@/panel/polls/types";
import DefaultPoll from "./templates/DefaultPoll.vue";
import OpenPoll from "./templates/OpenPoll.vue";
import ImagePoll from "./templates/ImagePoll.vue";

@Component({
  components: {
    DefaultPoll,
    OpenPoll,
    ImagePoll,
  },
})
export default class PollItem extends Vue {
  @Prop() readonly poll!: Poll;

  @Action("logEvent", { namespace: TRACKING_NAMESPACE })
  logEvent!: Function;

  @Action(PollAction.VOTE, { namespace: POLL_NAMESPACE })
  vote!: (payload: PollVoteActionPayload) => Promise<void>;

  @Action(PollAction.END_POLL, { namespace: POLL_NAMESPACE })
  endPoll!: (poll: Poll) => Promise<void>;

  componentName(): string {
    switch (this.poll.options.type) {
      case PollType.OPEN:
        return "OpenPoll";
      case PollType.IMAGE:
        return "ImagePoll";
      default:
        return "DefaultPoll";
    }
  }

  voteInProgress = false;

  get isActive() {
    return this.poll.status === PollStatus.ACTIVE;
  }

  // TODO: Move this into DefaultPoll.vue so we can listen to the API response
  async handleVote(pollAnswer: PollAnswer): Promise<void> {
    try {
      this.voteInProgress = true;
      await this.vote({ pollId: this.poll.id, pollVote: pollAnswer });
      this.logEvent({ ea: "vote", ec: "poll", ed: this.poll.id });
    } finally {
      this.voteInProgress = false;
    }
  }

  triggerPollEnd() {
    if (this.isActive) {
      this.endPoll(this.poll); // TODO: Event no longer needs to emitted, move into DefaultPoll.vue
    }
  }
}
