





















import { PollImage } from "@/panel/polls/types";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Config } from "@/shared/config/types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class PollQuestion extends Vue {
  @Prop() image!: PollImage;
  @Prop() question!: string;
  @Prop() title!: string;
  @Prop({ default: true }) toggleEnabled!: boolean;

  @Getter("config", { namespace: CONFIG_NAMESPACE })
  config!: Config;

  get imageUrl(): string {
    return this.image
      ? this.config.mediaUploadBaseUrl + this.image.publicPath
      : "";
  }

  toggle(): void {
    if (this.toggleEnabled) {
      this.$emit("toggle");
    }
  }
}
