import { CONFIG_NAMESPACE } from "./../../config/store/index";
import { SVResult } from "./../../../panel/secure-voting/types";
import { Ad, AdAction } from "@/panel/ads/types";
import { Poll, PollAction } from "@/panel/polls/types";
import { ApplicationType, AppRootState } from "@/shared/@types/app";
import {
  AppConfig,
  GeneralSettings,
  PollResultDisplay,
} from "@/shared/config/types";
import { IFRAME_BRIDGE_NAMESPACE } from "@/shared/iFrameBridge/store";
import { IFrameEventPayload, IFrameMessage } from "@/shared/iFrameBridge/types";
import Vue from "vue";
import { ActionTree } from "vuex";
import { NchanService } from "../nchan-service";
import { NchanMessage, NchanState } from "../types";

export const actions: ActionTree<NchanState, AppRootState> = {
  init(context, appConfig: AppConfig): NchanService[] {
    const clients: NchanService[] = [];

    // TODO: Update all nchan events to follow tenantId:broadcastId:channelId structure.
    // Currently polls emits to broadcastId and possibly others? Ads?
    clients.push(
      new NchanService(
        appConfig.nchanUrl,
        appConfig.broadcastId,
        (message: string) => context.dispatch("handleMessage", message)
      )
    );

    clients.push(
      new NchanService(
        appConfig.nchanUrl,
        appConfig.tenantId,
        (message: string) => context.dispatch("handleMessage", message)
      )
    );

    clients.push(
      new NchanService(
        appConfig.nchanUrl,
        appConfig.tenantId + ":" + appConfig.broadcastId,
        (message: string) => context.dispatch("handleMessage", message)
      )
    );

    context.commit("setClients", clients);
    return clients;
  },
  handleMessage(context, message: string): void {
    const nchanMessage: NchanMessage = JSON.parse(message);
    const messageType = nchanMessage.type || nchanMessage.payload["@type"];

    Vue.$log.debug("nchan handleMessage", { messageType });

    if (!messageType) {
      Vue.$log.debug(
        "Unhandled message, could not identify message type",
        nchanMessage
      );
      return;
    }

    const iFrameMessagePayload: IFrameEventPayload = {
      category: messageType,
      data: nchanMessage.payload || undefined,
    };

    const iFrameMessage: IFrameMessage = {
      type: "appEvent",
      payload: iFrameMessagePayload,
      source: context.rootState.applicationType,
    };

    // TODO: we should add a destination field to the nchan message on the backend
    // TODO: this is just a temporary solution to map a VideoAd message to a notification
    if (messageType === AdAction.VideoAd) {
      iFrameMessagePayload.category = "notification";
      iFrameMessagePayload.data = {
        id: (nchanMessage.payload as Ad).id,
        animationEntrance: "SLIDE_IN_LEFT",
        animationExit: "SLIDE_OUT_LEFT",
        ad: nchanMessage.payload,
        closableAfterSeconds: (nchanMessage.payload as Ad).closableAfterSeconds,
        percentageWidth: 50,
        timestamp: Date.now(),
      };
      iFrameMessage.destination = ApplicationType.OVERLAY;
    } else if (messageType === PollAction.POLL_RESULTS) {
      iFrameMessagePayload.category = "notification";
      iFrameMessagePayload.data = {
        id: (nchanMessage.payload as Poll).id,
        animationEntrance: "SLIDE_IN_LEFT",
        animationExit: "SLIDE_OUT_LEFT",
        poll: nchanMessage.payload,
        percentageWidth: 100,
        initialDisplay:
          (
            context.rootGetters[
              `${CONFIG_NAMESPACE}/settings`
            ] as GeneralSettings
          )?.pollsInitialResultDisplay ?? PollResultDisplay.BARS,
        timestamp: Date.now(),
      };
      iFrameMessage.destination = ApplicationType.OVERLAY;
    } else if (messageType === "SV_RESULTS") {
      iFrameMessagePayload.category = "notification";
      iFrameMessagePayload.data = {
        id: (nchanMessage.payload as SVResult).id,
        animationEntrance: "SLIDE_IN_LEFT",
        animationExit: "SLIDE_OUT_LEFT",
        svResult: nchanMessage.payload,
        percentageWidth: 100,
        timestamp: Date.now(),
      };
      iFrameMessage.destination = ApplicationType.OVERLAY;
    }

    // post message to destination
    if (iFrameMessage.destination) {
      context.dispatch(
        IFRAME_BRIDGE_NAMESPACE + "/postMessage",
        iFrameMessage,
        { root: true }
      );
    } else {
      // post message to all known applications
      const applicationTypes = Object.values(ApplicationType);
      for (const applicationType of applicationTypes) {
        iFrameMessage.destination = applicationType;
        context.dispatch(
          IFRAME_BRIDGE_NAMESPACE + "/postMessage",
          { ...iFrameMessage },
          { root: true }
        );
      }
    }
  },
};
