































































import { USER_NAMESPACE } from "@/shared/user/store";
import { Role, UserInfo } from "@/shared/user/types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { pollsService } from "../../../services/polls-service";
import { POLL_NAMESPACE } from "../../../store";
import { Poll, PollAction, PollStatus, PollVote } from "../../../types";

@Component({})
export default class PollModeratorActions extends Vue {
  @Prop({ required: true }) poll!: Poll;

  @Action(PollAction.POLL_MODERATOR_TOGGLE_RESULTS, {
    namespace: POLL_NAMESPACE,
  })
  toggleResults!: (pollId: string) => void;

  @Getter("userVotes", { namespace: POLL_NAMESPACE })
  readonly allUserVotes!: Record<string, PollVote[]>;

  @Getter("userInfo", { namespace: USER_NAMESPACE })
  readonly userInfo!: UserInfo | null;

  pollId = this.poll.id;

  confirmPublishResults = false;
  publishingResults = false;
  pollResultsPublished = false;
  showingOverlayResults = false;

  allowedRoles = [Role.ROLE_MODERATOR, Role.ROLE_ADMIN];

  get userVotes(): PollVote[] {
    return this.allUserVotes[this.pollId] || [];
  }

  get showPublishResultAction(): boolean {
    return (
      this.poll.status !== PollStatus.ENDED &&
      !this.poll.showResults &&
      !this.pollResultsPublished
    );
  }

  get showToggleResultAction(): boolean {
    return this.poll.status !== PollStatus.ENDED && !this.poll.showResults;
  }

  get showComponent(): boolean {
    if (
      this.userInfo?.roles.some((role) => this.allowedRoles.indexOf(role) > -1)
    ) {
      return (
        this.showPublishResultAction ||
        this.showToggleResultAction ||
        this.showOverlayResultsVisible
      );
    }
    return false;
  }

  async publishPollResult(): Promise<void> {
    this.publishingResults = true;
    await pollsService.publishPollResults(this.pollId);
    this.publishingResults = false;
    this.pollResultsPublished = true;
  }

  get showOverlayResultsVisible(): boolean {
    return this.poll.status === PollStatus.ENDED && this.poll.showResults;
  }

  async showOverlayResults(): Promise<void> {
    this.showingOverlayResults = true;
    await pollsService.triggerPollResults(this.pollId);
    this.showingOverlayResults = false;
  }
}
