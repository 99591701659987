import { PanelRootState } from "@/panel/store/PanelRootState";
import Vue from "vue";
import Vuex, { Module } from "vuex";
import { QAState } from "../types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

Vue.use(Vuex);

export const QA_NAMESPACE = "qa";

export const defaultState: QAState = {
  loading: false,
  questions: [],
};

export const qa: Module<QAState, PanelRootState> = {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
