import { RegistrationResponse } from "@/panel/gema/types";
import { PanelRootState } from "@/panel/store/PanelRootState";
import { apiService } from "@/shared/services/api-service";
import Vue from "vue";
import { ActionTree } from "vuex";
import { NavigationState } from "../types";

export const actions: ActionTree<NavigationState, PanelRootState> = {
  async getRegistration(context): Promise<void | RegistrationResponse> {
    const reg = await apiService.getMemberRegistration();
    if (reg) {
      context.commit("setRegistration", reg);
      return reg;
    }
  },

  async getNavbarItems(context, broadcastId: string | null): Promise<void> {
    try {
      const tabs = await apiService.getNavigationTabs(broadcastId);

      if (tabs) context.commit("setNavbarItems", tabs);
    } catch (error) {
      Vue.$log.debug("Fetch navigation error", error);
      context.dispatch(
        "tracking/logError",
        {
          ea: "error",
          ec: "navigationfetch",
          ed: error,
        },
        { root: true }
      );
    }
  },
};
