






















































import { PollAnswer, PollVote } from "@/panel/polls/types";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Config } from "@/shared/config/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class PollImageAnswers extends Vue {
  @Prop({ required: true }) answers!: PollAnswer[];
  @Prop({ required: true }) disabled!: boolean;
  @Prop({ required: true }) votes!: PollVote[];
  @Prop({ required: true }) votesPerPerson!: number;

  @Getter("config", { namespace: CONFIG_NAMESPACE })
  config!: Config;

  imageUrl(imagePath: string) {
    return this.config.mediaUploadBaseUrl + imagePath;
  }

  vote(pollAnswer: PollAnswer): void {
    if (this.isSelected(pollAnswer)) return;

    this.$emit("vote", pollAnswer);
  }

  get selectedAnswersIds(): string[] {
    return this.votes.map((vote) => vote.pollAnswer.answerId);
  }

  isSelected({ answerId }: PollAnswer) {
    return this.selectedAnswersIds.includes(answerId);
  }

  get votesRemaining(): number {
    return this.votesPerPerson - this.votes.length;
  }
}
