import { Route } from "vue-router";
import router from "../router";
import { NavigationId } from "./types";

export function navigate(
  pageName: NavigationId | string,
  paramsObj: { [key: string]: string } | undefined = undefined
): void {
  if (router.currentRoute.name !== pageName) {
    if (paramsObj) {
      router.push({ name: pageName, params: paramsObj });
    } else {
      router.push({ name: pageName });
    }
  }
}

export function openAndNavigate(openApp: () => void, route: Route): void {
  openApp();
  if (router.currentRoute.fullPath !== route.fullPath) {
    router.push({ path: route.path, params: route.params, query: route.query });
  }
}
