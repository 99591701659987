



















import { PollAnswer } from "@/panel/polls/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class PollResult extends Vue {
  @Prop({ required: true }) answer!: PollAnswer;
  @Prop({ required: true }) percent!: number;
  @Prop({ required: true }) selected!: boolean;
  @Prop({ default: false }) absolute!: boolean;

  get progressBackgroundColor(): string {
    return this.$vuetify.theme.dark
      ? "background lighten-1"
      : "background darken-1";
  }

  get progressColor(): string {
    if (this.answer.userSubmission) {
      return this.$vuetify.theme.dark ? "brand lighten-2" : "brand darken-2";
    }
    return "brand";
  }

  get voteDisplay(): string {
    return this.absolute
      ? `${this.answer.voteCount} (${this.percent.toFixed(2)}%)`
      : `${this.percent}%`;
  }
}
