import { Resource } from "../types";

export function getResourceFilename(resource: Resource): string {
  return resource.title || resource.upManMedia.filename;
}

export function getResourceFileTypeLabel(mimeType: string): string {
  const [type, subtype] = mimeType.split("/");

  switch (type.toLowerCase()) {
    case "image":
      return "IMAGE";
    case "text":
      return "TEXT";
    case "audio":
      return "AUDIO";
    case "video":
      return "VIDEO";
  }

  if (subtype) {
    switch (subtype.toLowerCase()) {
      case "pdf":
        return "PDF";
      case "msword":
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "vnd.oasis.opendocument.text":
      case "rtf":
        return "Document";

      case "vnd.openxmlformats-officedocument.presentationml.presentation":
      case "vnd.ms-powerpoint":
      case "vnd.oasis.opendocument.presentation":
        return "Powerpoint";

      case "vnd.oasis.opendocument.spreadsheet":
      case "vnd.ms-excel":
      case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "xml":
        return "Spreadsheet";

      case "x-7z-compressed":
        return "7-Zip";

      case "x-tar":
        return "XTar";

      case "zip":
      case "epub+zip":
      case "gzip":
        return "ZIP";
    }
  }

  return "File";
}
