import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { GemaState } from "../types";
import { PanelRootState } from "@/panel/store/PanelRootState";

export const state: GemaState = {
  member: null,
  representedMembers: [],
  registrationSettings: null,
  svSettings: null,
};

export const GEMA_NAMESPACE = "gema";

const namespaced = true;

export const gema: Module<GemaState, PanelRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
