import { AppRootState } from "@/shared/@types/app";
import { Module } from "vuex";
import { UserState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const USER_NAMESPACE = "user";

const namespaced = true;

export const state: UserState = {
  created: false,
  hasUpdateError: false,
};

export const user: Module<UserState, AppRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
