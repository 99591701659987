


















































































































import { Attendance, RegistrationResponse } from "@/panel/gema/types";
import { IFrameMessage } from "@/shared/iFrameBridge/types";
import { apiService } from "@/shared/services/api-service";
import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo } from "@/shared/user/types";
import { hexToRGB, isDark } from "@/shared/utils";
import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { QA_NAMESPACE } from "../../store";

import {
  QAGetter,
  Question,
  QAAction,
  QASnackBarError,
  QuestionStatus,
  QuestionDto,
  QuestionType,
} from "../../types";
import QuestionList from "../QuestionList.vue";
import GemaQuestionInput from "./GemaQuestionInput.vue";

@Component({
  components: {
    QuestionList,
    GemaQuestionInput,
  },
})
export default class GemaQAPanel extends Vue {
  @Getter(QAGetter.Questions, { namespace: QA_NAMESPACE })
  questions!: Question[];

  @Getter("userInfo", { namespace: USER_NAMESPACE })
  userInfo!: UserInfo;

  @Action(QAAction.FetchQuestions, { namespace: QA_NAMESPACE })
  fetchQuestions!: () => Promise<void>;

  @Action(QAAction.AddQuestion, { namespace: QA_NAMESPACE })
  addQuestion!: (question: Question) => Promise<void>;

  @Action(QAAction.CreateQuestion, { namespace: QA_NAMESPACE })
  saveQuestion!: (payload: QuestionDto) => Promise<void>;

  @Action(QAAction.DeleteQuestion, { namespace: QA_NAMESPACE })
  removeQuestion!: (questionId: string) => Promise<void>;

  // IFRAME_BRIDGE_NAMESPACE = undefined?
  @Action("iFrameBridge/postMessage")
  postMessage!: (message: IFrameMessage) => void;

  showQuestionInput = false;
  questionCategory = "";

  snackbar = false;
  snackbarMessage = "";
  snackbarColor = "error";

  loading = true;

  registration: RegistrationResponse | null = null;

  async created() {
    const [registration] = await Promise.all([
      apiService.getMemberRegistration().catch(() => {
        //
      }),
      this.fetchQuestions(),
    ]);

    this.registration = registration || null;
    this.loading = false;
  }

  dismissSnackbar() {
    this.snackbar = false;
    this.snackbarMessage = "";
    this.snackbarColor = "error";
  }

  setSnackbarError($event: QASnackBarError) {
    this.snackbarMessage = $event.message;
    this.snackbarColor = $event.color || "error";
    this.snackbar = true;
  }

  goToQuestionInput(category: string) {
    this.questionCategory = category;
    this.showQuestionInput = true;
  }

  goToQuestionPanel() {
    this.questionCategory = "";
    this.showQuestionInput = false;
  }

  get myQuestions() {
    return this.questions.filter(
      (question) => question.user.userId === this.userInfo.userId
    );
  }

  get approvedQuestions() {
    return this.questions.filter(
      (question) => question.status === QuestionStatus.APPROVED
    );
  }

  get inPersonQuestions() {
    return this.myQuestions.filter(
      (q) =>
        q.type === QuestionType.IN_PERSON &&
        ![
          QuestionStatus.DISMISSED,
          QuestionStatus.ANSWERING,
          QuestionStatus.ANSWERED,
        ].includes(q.status)
    );
  }

  get brandTextColorLight(): boolean {
    const col = this.$vuetify.theme.currentTheme.brand?.toString();
    return !col || isDark(hexToRGB(col));
  }

  get isAttendingInPerson(): boolean {
    return this.registration?.registration?.attendanceType &&
      [Attendance.IN_PERSON, Attendance.BY_REPRESENTATIVE_IN_PERSON].includes(
        this.registration?.registration?.attendanceType
      )
      ? true
      : false;
  }

  emitVideoRequest(): void {
    this.postMessage({
      type: "appEvent",
      payload: {
        category: "gema",
        action: "START_VIDEO_QUESTION",
      },
    });
  }

  async emitInPersonVideoRequest(): Promise<void> {
    if (this.hasRequestedInPersonQuestion) {
      this.inPersonQuestions.forEach((question) =>
        this.removeQuestion(question.id)
      );
    } else {
      await this.saveQuestion({
        content: "Redebeitrag",
        note: "",
        type: QuestionType.IN_PERSON,
        category: "",
      });
    }
  }

  get hasRequestedInPersonQuestion() {
    return this.inPersonQuestions.length > 0;
  }
}
