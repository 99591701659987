

























import { Component, Prop, Vue } from "vue-property-decorator";
import { CustomPollVoteDto } from "../../../types";

@Component({})
export default class PollUserInput extends Vue {
  @Prop({ required: true }) disabled!: boolean;
  @Prop({ required: true }) maxCharacterCount!: number;
  @Prop({ required: true }) singleWordAnswers!: boolean;

  userInput = "";

  get userInputRules() {
    const rules = [this.maxCharacterCountFormRule];

    if (this.singleWordAnswers) {
      rules.push(this.singleWordAnswersFormRule);
    }

    return rules;
  }

  valid = true;

  maxCharacterCountFormRule(v: string) {
    v = v.trim();
    return (
      this.maxCharacterCount <= -1 ||
      v.length <= this.maxCharacterCount ||
      this.$t("polls.answerLimit", {
        chars: this.maxCharacterCount,
      }).toString()
    );
  }

  singleWordAnswersFormRule(v: string) {
    const regex = /^[\w-]+$/;
    v = v.trim();
    return (
      !v.length ||
      regex.test(v) ||
      this.$t("polls.singleWordValidationMessage", {
        chars: this.maxCharacterCount,
      }).toString()
    );
  }

  customVote() {
    const userInput = this.userInput.trim();

    if (this.disabled || !this.valid || !userInput.length) return;

    const customPollAnswer: CustomPollVoteDto = {
      answerId: "",
      value: userInput,
    };
    this.$emit("vote", customPollAnswer);
  }
}
