

































































import { Component, Prop, Vue } from "vue-property-decorator";
import PollResult from "./PollResult.vue";
import VueWordCloud from "vuewordcloud";

import { PollAnswer, PollVote } from "@/panel/polls/types";
import { Getter } from "vuex-class";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { GeneralSettings, PollResultDisplay } from "@/shared/config/types";

@Component({
  components: {
    PollResult,
    VueWordCloud,
  },
})
export default class PollResults extends Vue {
  @Prop({ required: true }) answers!: PollAnswer[];
  @Prop({ required: true }) votes!: PollVote[];
  @Prop({ default: false }) wordCloud!: boolean;
  @Prop({}) wordCloudHeight?: string;
  @Prop({ default: false }) showAuthorNames!: boolean;
  @Prop({ default: false }) absolute!: boolean;
  @Prop({ default: 0 }) decimals!: number;
  @Prop({ default: PollResultDisplay.BARS }) initialDisplay!: PollResultDisplay;

  @Getter("settings", { namespace: CONFIG_NAMESPACE })
  settings?: GeneralSettings;

  wordCloudVisible = this.initialDisplay === PollResultDisplay.WORDCLOUD;
  mounted() {
    if (this.settings && this.settings.pollsInitialResultDisplay) {
      this.wordCloudVisible =
        this.settings.pollsInitialResultDisplay === PollResultDisplay.WORDCLOUD;
    }
  }

  get wordCloudData(): (string | number)[][] {
    return this.answers
      .filter((answer) => answer.voteCount > 0)
      .map((answer: PollAnswer) => [answer.value, answer.voteCount]);
  }

  calcPercent(votes: number, decimals = 0): number {
    const total = this.answers.reduce((a, b) => {
      return a + b.voteCount;
    }, 0);

    if (total === 0) {
      return 0;
    }
    return (
      Math.round((votes / total) * Math.pow(10, decimals + 2)) /
      Math.pow(10, decimals)
    );
  }

  selected(answerId: string): boolean {
    return this.votes.some(
      (pollVote) => pollVote.pollAnswer.answerId === answerId
    );
  }
}
