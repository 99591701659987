import { API_BASE_URL } from "@/helpers/envConsts";
import { getAxiosInstance } from "@/shared/services/axios-base";
import { AxiosInstance } from "axios";
import { Store } from "vuex";
import {
  Question,
  QuestionDto,
  QuestionPatchDto,
  QuestionStatus,
} from "../types";

class IAService {
  private qaEndpoint = "/qa";

  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async fetchQuestions(broadcastId: string): Promise<Question[]> {
    return this.axios
      .get<Question[]>(this.qaEndpoint, {
        params: {
          broadcastId: broadcastId,
          includeUserQuestions: true, // Includes all users questions regardless of status
          status: QuestionStatus.APPROVED,
        },
      })
      .then((res) => res.data);
  }

  async createQuestion(
    broadcastId: string,
    question: QuestionDto
  ): Promise<Question> {
    return this.axios
      .post(this.qaEndpoint, question, {
        params: {
          broadcastId: broadcastId,
        },
      })
      .then((res) => res.data);
  }

  async updateQuestion(
    questionId: string,
    question: QuestionPatchDto
  ): Promise<Question[]> {
    return this.axios
      .patch(this.qaEndpoint, question, {
        params: {
          questionId: questionId,
        },
      })
      .then((res) => res.data);
  }

  async deleteQuestion(id: string): Promise<void> {
    return this.axios.delete(this.qaEndpoint + `/${id}`);
  }
}

let iaService: IAService;

function initIAService(store: Store<unknown>): IAService {
  if (iaService) {
    return iaService;
  }

  if (!API_BASE_URL) {
    throw new Error("Missing env var - BASE_URL_IA");
  }

  const axiosInstance = getAxiosInstance(
    {
      baseURL: API_BASE_URL,
      responseType: "json",
      withCredentials: true,
    },
    store
  );

  iaService = new IAService(axiosInstance);
  return iaService;
}

export { initIAService, iaService };
