var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"pa-2 product interaction",style:({
      'box-shadow': _vm.hovering ? '0 0 0 2px #FC4C02' : 'none',
    }),attrs:{"color":"secondary"},on:{"mouseover":function($event){_vm.hovering = true},"mouseout":function($event){_vm.hovering = false},"click":function($event){return _vm.clickAction($event, _vm.product)}}},[_c('v-row',{attrs:{"align":"end","justify":"end"}},[_c('v-sheet',{staticClass:"mt-1 mr-1",staticStyle:{"background-color":"rgba(128, 128, 128, 0.7)","z-index":"3"},attrs:{"elevation":"0","height":"25","width":"25"}},[_c('v-icon',[_vm._v("mdi-launch")])],1)],1),_c('v-img',{staticClass:"product-img",attrs:{"lazy-src":_vm.product.url,"src":_vm.product.url,"aspect-ratio":"1","color":"grey lighten-2"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])}),(_vm.product.badge)?_c('div',[_c('div',{staticClass:"badge",class:{ badge2: _vm.hovering },style:({
          'background-color': '#FC4C02',
        })}),_c('div',{staticClass:"badge-text"},[_c('p',[_vm._v(_vm._s(_vm.product.badge))])])]):_vm._e(),_c('v-card-text',{staticClass:"header",style:({
        height: '7em',
        'line-height': 1.2,
        overflow: 'hidden',
      })},[_c('span',[_vm._v(_vm._s(_vm.product.itemName))]),_c('br'),(_vm.product.itemPrice)?_c('span',{staticClass:"price",attrs:{"color":"brand"}},[_vm._v(_vm._s(_vm.getCurrency(_vm.product.currency))+" "+_vm._s(_vm.product.itemPrice))]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }