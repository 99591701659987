import { AppRootState } from "@/shared/@types/app";
import { IFRAME_BRIDGE_NAMESPACE } from "@/shared/iFrameBridge/store";
import Vue from "vue";
import { ActionTree } from "vuex";
import { authService } from "../services/auth-service";
import { AuthenticationPayload, AuthState } from "../types";

export const actions: ActionTree<AuthState, AppRootState> = {
  async authenticate(
    context,
    payload: AuthenticationPayload
  ): Promise<boolean> {
    if (payload.tenantToken) {
      try {
        const response = payload.demo
          ? await authService.post("/auth/demo", null, {
              params: {
                demoUserId: payload.tenantToken,
                tenantId: payload.tenantId,
              },
              // anything but json is rejected by server, even with empty body
              headers: {
                "Content-Type": "application/json",
              },
            })
          : await authService.post(`/auth/log-in`, null, {
              params: {
                tenantId: payload.tenantId,
                externalJwt: payload.tenantToken,
                isFusionAuthToken: payload.isFusionAuth,
              },
              // anything but json is rejected by server, even with empty body
              headers: {
                "Content-Type": "application/json",
              },
            });
        Vue.$log.debug("Authentication success", response.data);
        context.commit("accessToken", response.data.accessToken);
        context.commit("refreshToken", response.data.refreshToken);
        context.commit("authenticated", true);
        const user = response.data.user;
        context.commit("user/setUser", user, { root: true });
        return true;
      } catch (error) {
        context.commit("resetAuthentication");
        Vue.$log.debug("Authentication error", error);
        context.dispatch(
          "tracking/logError",
          {
            ea: "error",
            ec: "auth",
            ed: error,
          },
          { root: true }
        );
        context.dispatch(
          IFRAME_BRIDGE_NAMESPACE + "/postMessage",
          {
            type: "appEvent",
            payload: {
              category: "app",
              action: "authFailed",
            },
          },
          { root: true }
        );
        return false;
      }
    } else {
      context.commit("resetAuthentication");
      return false;
    }
  },
  async refresh(context): Promise<string | null> {
    try {
      const refreshToken = context.state.refreshToken;
      if (refreshToken === undefined) {
        return null;
      }
      const response = await authService.post(`/auth/refresh`, null, {
        params: {
          refreshToken: refreshToken,
        },
      });
      const authObj = response.data;
      context.commit("accessToken", authObj.accessToken);
      context.commit("refreshToken", authObj.refreshToken);
      return authObj.accessToken;
    } catch (error) {
      context.commit("resetAuthentication");
      Vue.$log.debug("Refresh token error", error);
      context.dispatch(
        "tracking/logError",
        {
          ea: "error",
          ec: "refresh-token",
          ed: error,
        },
        { root: true }
      );
      context.dispatch(
        IFRAME_BRIDGE_NAMESPACE + "/postMessage",
        {
          type: "appEvent",
          payload: {
            category: "app",
            action: "authFailed",
          },
        },
        { root: true }
      );
    }
    return null;
  },
};
