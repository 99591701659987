import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import EN from "./locales/en.json";
import DE from "./locales/de.json";
import { I18N_DEFAULT_LOCALE_OVERRIDE } from "@/helpers/envConsts";

Vue.use(VueI18n);

export const DEFAULT_LOCALE =
  I18N_DEFAULT_LOCALE_OVERRIDE || navigator.language.split("-")[0] || "en";
export const FALLBACK_LOCALE = "en";

export type TranslationKeys = typeof EN;

export enum Locale {
  EN = "en",
  DE = "de",
}

export const baseMessages: Record<Locale, TranslationKeys> = {
  [Locale.EN]: EN,
  [Locale.DE]: DE,
};

export const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  messages: baseMessages,
});

export const mergeI18nMessages = (
  i18n: VueI18n,
  messages: LocaleMessages
): VueI18n => {
  Object.keys(messages).forEach((locale) =>
    i18n.mergeLocaleMessage(locale, messages[locale])
  );

  return i18n;
};
