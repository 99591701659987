import { PanelRootState } from "@/panel/store/PanelRootState";
import { GetterTree } from "vuex";
import { PollState } from "../types";
import { Poll, PollVote } from "@/panel/polls/types";

export const getters: GetterTree<PollState, PanelRootState> = {
  isLoading(state): boolean {
    return state.isLoading;
  },
  polls(state): Poll[] {
    return state.polls;
  },
  userVotes(state): Record<string, PollVote[]> {
    return state.pollVotes;
  },
};
