import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { MerchState } from "../types";
import { PanelRootState } from "@/panel/store/PanelRootState";

export const MERCH_NAMESPACE = "merch";

const namespaced = true;

export const state: MerchState = {
  // merch: [],
  shopCategories: [],
  shopItems: [],
  disclaimers: [],
};

export const merch: Module<MerchState, PanelRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
