import Vue from "vue";
import { Mutation } from "vuex";
import { QAMutation, QAState, Question } from "../types";

export const mutations: Record<QAMutation, Mutation<QAState>> = {
  [QAMutation.SetQuestions](state, questions: Question[]) {
    state.questions = questions;
  },
  [QAMutation.UpdateQuestion](state, question: Question) {
    const index = state.questions.findIndex((q) => q.id === question.id);
    if (index > -1) {
      Vue.set(state.questions, index, question);
    } else {
      state.questions.push(question);
    }
  },
  [QAMutation.RemoveQuestion](state, questionId: string) {
    state.questions = state.questions.filter(
      (question) => question.id !== questionId
    );
  },
  [QAMutation.AddQuestion](state, question: Question) {
    state.questions.push(question);
  },
  [QAMutation.SetLoading](state, loading: boolean) {
    state.loading = loading;
  },
};
