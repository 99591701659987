import { PanelRootState } from "@/panel/store/PanelRootState";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Action, ActionContext } from "vuex";
import { iaService } from "../services/ia-service";
import { QAAction, QAMutation, QAState, Question, QuestionDto } from "../types";

const fetchQuestions = async ({
  commit,
  rootGetters,
  state,
}: ActionContext<QAState, PanelRootState>): Promise<void> => {
  if (state.loading) return;

  try {
    commit(QAMutation.SetLoading, true);
    const appConfig = rootGetters[CONFIG_NAMESPACE + "/appConfig"];
    commit(
      QAMutation.SetQuestions,
      await iaService.fetchQuestions(appConfig.broadcastId)
    );
  } catch (error) {
    return Promise.reject(error);
  } finally {
    commit(QAMutation.SetLoading, false);
  }
};

const addQuestion = async (
  { commit }: ActionContext<QAState, PanelRootState>,
  question: Question
) => {
  commit(QAMutation.AddQuestion, question);
};

const createQuestion = async (
  { state, commit, rootGetters }: ActionContext<QAState, PanelRootState>,
  question: QuestionDto
) => {
  const appConfig = rootGetters[CONFIG_NAMESPACE + "/appConfig"];

  const savedQuestion = await iaService.createQuestion(
    appConfig.broadcastId,
    question
  );

  if (state.questions.findIndex((q) => q.id === savedQuestion.id) === -1) {
    commit(QAMutation.AddQuestion, savedQuestion);
  }
};

const updateQuestion = async (
  { commit }: ActionContext<QAState, PanelRootState>,
  question: Question
) => {
  commit(QAMutation.UpdateQuestion, question);
};

const deleteQuestion = async (
  { commit }: ActionContext<QAState, PanelRootState>,
  questionId: string
) => {
  await iaService.deleteQuestion(questionId);
  commit(QAMutation.RemoveQuestion, questionId);
};

const removeQuestion = async (
  { commit }: ActionContext<QAState, PanelRootState>,
  questionId: string
) => {
  commit(QAMutation.RemoveQuestion, questionId);
};

export const actions: Record<QAAction, Action<QAState, PanelRootState>> = {
  [QAAction.FetchQuestions]: fetchQuestions,
  [QAAction.CreateQuestion]: createQuestion,
  [QAAction.AddQuestion]: addQuestion,
  [QAAction.UpdateQuestion]: updateQuestion,
  [QAAction.DeleteQuestion]: deleteQuestion,
  // Nchan Actions
  [QAAction.QuestionAsked]: updateQuestion,
  [QAAction.QuestionEdited]: updateQuestion,
  [QAAction.QuestionDeleted]: removeQuestion,
};
