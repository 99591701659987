import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Store } from "vuex";

export function getAxiosInstance(
  axiosConfig: AxiosRequestConfig,
  store: Store<unknown>
): AxiosInstance {
  const axiosInstance = axios.create(axiosConfig);

  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = store.getters["auth/accessToken"];
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken: string | null = await store.dispatch("auth/refresh");
        if (accessToken !== null) {
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}
