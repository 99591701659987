import Vue from "vue";
import { MutationTree } from "vuex";
import { AuthState } from "../types";

export const mutations: MutationTree<AuthState> = {
  authenticated(state, authenticated: boolean) {
    Vue.set(state, "authenticated", authenticated);
  },
  accessToken(state, accessToken: string) {
    Vue.set(state, "accessToken", accessToken);
  },
  resetAuthentication(state) {
    Vue.set(state, "authenticated", false);
    Vue.set(state, "accessToken", undefined);
    Vue.set(state, "refreshToken", undefined);
  },
  refreshToken(state, refreshToken: string) {
    Vue.set(state, "refreshToken", refreshToken);
  },
};
