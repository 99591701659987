import { AppRootState } from "@/shared/@types/app";
import { Module } from "vuex";
import { AuthState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state: AuthState = {
  authenticated: false,
};

export const AUTH_NAMESPACE = "auth";

const namespaced = true;

export const auth: Module<AuthState, AppRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
