import { PanelRootState } from "@/panel/store/PanelRootState";
import { Getter } from "vuex";
import { SVGetter, SVQuestion, SVState } from "../types";

export const getters: Record<SVGetter, Getter<SVState, PanelRootState>> = {
  [SVGetter.ActiveQuestion](state): SVQuestion | null {
    return state.activeQuestion;
  },
  [SVGetter.LiveQuestion](state): SVQuestion | null {
    return state.activeQuestion &&
      state.activeQuestion.status === "LIVE" &&
      !state.questionsDone.some((id) => state.activeQuestion?.id === id)
      ? state.activeQuestion
      : null;
  },
  [SVGetter.Loading](state): boolean {
    return state.loading;
  },
  [SVGetter.Hash](state): string | undefined {
    return state.hash;
  },
  [SVGetter.SecureStorage](
    state
  ): { pubKey: string; privKey: string } | undefined {
    return state.verySecureStorage;
  },
  [SVGetter.AvailableVotes](state): number {
    return state.availableVotes;
  },
};
