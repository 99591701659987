import { AppRootState } from "@/shared/@types/app";
import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { NchanState } from "../types";

export const state: NchanState = {};

export const NCHAN_NAMESPACE = "nchan";

const namespaced = true;

export const nchan: Module<NchanState, AppRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
