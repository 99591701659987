import { GemaGetter, RegistrationResponse } from "@/panel/gema/types";
import { AppConfig } from "@/shared/config/types";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { apiService } from "@/shared/services/api-service";
import { SVVotePayload } from "./../types";
import { PanelRootState } from "@/panel/store/PanelRootState";
import { Action, ActionContext } from "vuex";
import { SVAction, SVMutation, SVQuestion, SVState } from "../types";
import { GEMA_NAMESPACE } from "@/panel/gema/store";

const getActiveQuestion = async ({
  commit,
  rootGetters,
}: ActionContext<SVState, PanelRootState>): Promise<SVQuestion> => {
  // if (state.loading) return;

  const { broadcastId }: AppConfig =
    rootGetters[`${CONFIG_NAMESPACE}/appConfig`];

  commit(SVMutation.SetLoading, true);
  const questions = await apiService.getSVQuestions("LIVE", broadcastId);
  if (questions && questions.length)
    commit(SVMutation.SetActiveQuestion, questions[0]);
  // commit(SVMutation.SetQuestions, questions);
  commit(SVMutation.SetLoading, false);
  return questions[0];
};

const handleQuestionChange = async (
  { commit, rootGetters }: ActionContext<SVState, PanelRootState>,
  question: SVQuestion | null
) => {
  if (!question) throw Error("No question in nchan message");
  const me: RegistrationResponse =
    rootGetters[`${GEMA_NAMESPACE}/${GemaGetter.Member}`];
  const representedMembers: RegistrationResponse[] =
    rootGetters[`${GEMA_NAMESPACE}/${GemaGetter.RepresentatedMembers}`];
  const m = me.member.group === "SV" ? representedMembers[0].member : me.member;
  if (!m) throw Error("Member could not be determined");

  const forAll = !question.targetGroup && !question.targetMemberStatus;

  // If we don't have access to registration for whatever reason, just show the question
  if (!me || forAll) commit(SVMutation.SetActiveQuestion, question);
  else if (
    (question.targetMemberStatus &&
      question.targetMemberStatus === m.memberStatus) ||
    (question.targetGroup && question.targetGroup === m.group)
  )
    commit(SVMutation.SetActiveQuestion, question);

  // if (state?.activeQuestion?.status === "LIVE")
  //   commit(SVMutation.SetActiveQuestion, null);
  // else {
  //   const { broadcastId }: AppConfig = rootGetters[
  //     `${CONFIG_NAMESPACE}/appConfig`
  //   ];
  //   let voted = "";
  //   if (question?.status === "LIVE")
  //     voted = await apiService.getQuestionVotedStatus(broadcastId);
  //   if (voted === "false") commit(SVMutation.SetActiveQuestion, question);
  // }

  // console.log(state.questions);
  // if (state.questions.length === 0) {
  //   commit(SVMutation.AddQuestion, question);
  //   commit(SVMutation.SetActiveQuestion, question);
  //   return;
  // } else if (state.questions.length === 1) {
  //   console.log(state.activeQuestion);
  //   commit(SVMutation.SetQuestion, {
  //     questionId: state.activeQuestion?.id,
  //     question
  //   });
  //   console.log(state.activeQuestion);
  //   return;
  // }

  // commit(SVMutation.SetQuestion, {
  //   questionId: state.activeQuestion?.id,
  //   question
  // });
  // console.log(state.questions);
  // const newActiveQuestion: SVQuestion = getters[SVGetter.FirstLiveQuestion];
  // commit(SVMutation.SetActiveQuestion, newActiveQuestion);
};

const setActiveQuestion = async (
  { commit }: ActionContext<SVState, PanelRootState>,
  question: SVQuestion | null
) => {
  commit(SVMutation.SetActiveQuestion, question);
};

const prepareCrypto = ({
  state,
  commit,
}: ActionContext<SVState, PanelRootState>): Promise<void> => {
  const hash = state.hash;
  if (!hash) throw Error("missing pw");
  return apiService
    .getUserKeys()
    .then((res) => {
      return apiService.decodeUserKeys(res, hash);
    })
    .then((res) => {
      commit(`${SVMutation.SetSecureStorage}`, {
        privKey: res.decryptedPrivateKey,
        pubKey: res.decryptedPublicKey,
      });
    });
};

const setHash = (
  { commit }: ActionContext<SVState, PanelRootState>,
  hash: string
): void => {
  commit(`${SVMutation.SetHash}`, hash);
};

const submitVote = async (
  { state, rootGetters, commit }: ActionContext<SVState, PanelRootState>,
  votes: SVVotePayload[]
): Promise<void> => {
  if (!state.verySecureStorage?.privKey) throw Error("no priv key");
  const { broadcastId }: AppConfig =
    rootGetters[`${CONFIG_NAMESPACE}/appConfig`];

  return apiService
    .submitVotes(
      votes,
      votes[0].questionId,
      state.verySecureStorage?.pubKey,
      broadcastId,
      state.verySecureStorage?.privKey
    )
    .then(() => {
      commit(SVMutation.AddQuestionDone, votes[0].questionId);
      return;
    });
};

const setSecureStorage = (
  { commit }: ActionContext<SVState, PanelRootState>,
  payload: { privKey: string; pubKey: string }
): void => {
  commit(`${SVMutation.SetSecureStorage}`, payload);
};

const getAvailableVotes = async (
  { commit }: ActionContext<SVState, PanelRootState>,
  questionId: string
): Promise<number | void> => {
  const availableVotes = await apiService.availableVotes(questionId);
  if (availableVotes && availableVotes.numAvailableVotes) {
    commit(`${SVMutation.SetAvailableVotes}`, availableVotes.numAvailableVotes);
    return availableVotes.numAvailableVotes;
  }
};

export const actions: Record<SVAction, Action<SVState, PanelRootState>> = {
  [SVAction.GetActiveQuestion]: getActiveQuestion,
  [SVAction.SetActiveQuestion]: setActiveQuestion,
  [SVAction.SubmitVote]: submitVote,
  [SVAction.PrepareCrypto]: prepareCrypto,
  [SVAction.SetHash]: setHash,
  [SVAction.SetSecureStorage]: setSecureStorage,
  [SVAction.HandleQuestionChange]: handleQuestionChange,
  [SVAction.GetAvailableVotes]: getAvailableVotes,
};
