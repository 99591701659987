import { NavigationItem } from "@/panel/navigation/types";
import { DeepPartial, StyleConfig } from "@vaudience/spect8-chat-ui-vue";

export interface ConfigState {
  initialized: boolean;
  appConfig?: AppConfig;
  broadcastConfig?: Config;
  tenantConfig?: Config;
  options: Options;
  agenda: string;
  tenantName: string;
  settings?: GeneralSettings;
}

export interface AppConfig {
  platform: string;
  tenant: string;
  tenantId: string;
  broadcastId: string;
  broadcastName: string;
  tenantToken: string;
  nchanUrl: string;
}

export interface Config {
  navigation: NavigationItem[];
  features: FeatureConfig;
  mediaUploadBaseUrl: string;
}

export interface FeatureConfig {
  available: Feature[];
  enabled: Feature[];
}

export interface FeatureResponse {
  id: string;
  tenantId: string;
  broadcastId: string | null;
  features: Feature[];
}

export interface TenantResponse {
  allowedFeatures: Feature[];
  demoMode: string;
  fusionAuthAppId: string;
  mediaUploadBaseUrl: string;
  name: string;
  tenantId: string;
}

export enum Feature {
  THREADS = "THREADS",
  FOOTER = "FOOTER",
  USER_LIST = "USER_LIST",
  CHANNEL_LIST = "CHANNEL_LIST",
  COMPACT_MESSAGES = "COMPACT_MESSAGES",
  NAME_COLORS = "NAME_COLORS",
  LOYALTY = "LOYALTY",
  REPORTS = "REPORTS",
  IGNORE = "IGNORE",
  HISTORY = "HISTORY",
  VIDEO_CONFERENCING = "VIDEO_CONFERENCING",
}

export interface Broadcast {
  broadcastName: string;
  id: string;
  tenantId: string;
  agenda: string;
}

export interface Options {
  demo: boolean;
  isFusionAuth: boolean;
  initialVisibility: boolean;
  hiddenAppbar: boolean;
  profile: boolean;
  profilePictures: boolean;
  collapsible: boolean;
  defaultLanguage: string;
  chatConfig: {
    style?: DeepPartial<StyleConfig>;
  };
}

// General settings aka chat-settings aka chatConfig
export interface GeneralSettings {
  id: string;
  tenantId: string;
  broadcastId: string;

  modesAvailable: Array<ChatMode>; // moderators can switch between available modes
  mode: ChatMode;
  allowedCharactersCount: number; // max length of a single message
  maxSameMessageCount: number; // max number of repeated identical messages
  // rate limiting
  maxMessageCountByTime: number;
  maxMessageCountSeconds: number;
  // rate limiting when in slow mode (optional)
  maxMessageCountByTimeSlow: number;
  maxMessageCountSecondsSlow: number;
  // Defines how long messages are accessible
  messageRetentionHours: number;
  // Defines which messages to show to new channel subscribers
  displayPastMessagesAge: number; // max age of displayed messages in seconds
  displayPastMessagesCount: number; // max number of displayed messages
  allowLink: boolean;
  // domainFilter: unknown; // more specific
  userColorMode: UserColorMode;
  enableSpamProtection: boolean;
  maxSameMessageResetTime: number;
  loadMessageHistory: boolean;
  pollsInitialResultDisplay: PollResultDisplay;
}

// Modes define certain restrictions for users
// 'all', 'subscribers' & 'followers' set a minimum class requirement for voice rights
// 'slow' allows everybody to talk but with more restrictive timings
export enum ChatMode {
  all = "all",
  subscribers = "subscribers",
  followers = "followers",
  slow = "slow",
}

export enum UserColorMode {
  NONE = "NONE",
  RANDOM = "RANDOM",
  PICK_FROM_SET = "PICK_FROM_SET",
  PICK_ANY = "PICK_ANY",
}

export enum PollResultDisplay {
  WORDCLOUD = "WORDCLOUD",
  BARS = "BARS",
}
