import { PanelRootState } from "@/panel/store/PanelRootState";
import { apiService } from "@/shared/services/api-service";
import { Action, ActionContext } from "vuex";
import {
  Resource,
  ResourceAction,
  ResourceMutation,
  ResourceState,
} from "../types";

const fetchResources = async (
  { state, commit }: ActionContext<ResourceState, PanelRootState>,
  broadcastId?: string
): Promise<void> => {
  if (state.loading || state.loaded) return;

  try {
    commit(ResourceMutation.SetLoading, true);
    commit(
      ResourceMutation.SetResources,
      await apiService.getResources(true, broadcastId)
    );
  } catch (error) {
    return Promise.reject(error);
  } finally {
    commit(ResourceMutation.SetLoading, false);
    commit(ResourceMutation.SetLoaded, true);
  }
};

const addResource = async (
  { state, commit }: ActionContext<ResourceState, PanelRootState>,
  resource: Resource
) => {
  const resourceExist = resource.broadcastIds
    ? state.broadcastResources.findIndex((r) => r.id === resource.id)
    : state.tenantResources.findIndex((r) => r.id === resource.id);

  if (resourceExist === -1) {
    commit(ResourceMutation.AddResource, resource);
  }
};

const deleteResource = async (
  { commit }: ActionContext<ResourceState, PanelRootState>,
  resource: Resource
) => {
  commit(ResourceMutation.RemoveResource, resource.id);
};

export const actions: Record<
  ResourceAction,
  Action<ResourceState, PanelRootState>
> = {
  [ResourceAction.FetchResources]: fetchResources,
  [ResourceAction.AddResource]: addResource,
  [ResourceAction.DeleteResource]: deleteResource,
};
