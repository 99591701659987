import { PanelRootState } from "@/panel/store/PanelRootState";
import Vue from "vue";
import Vuex, { Module } from "vuex";
import { ResourceState } from "../types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

Vue.use(Vuex);

export const RESOURCE_NAMESPACE = "resources";

export const defaultState: ResourceState = {
  loading: false,
  loaded: false,
  broadcastResources: [],
  tenantResources: [],
};

export const resources: Module<ResourceState, PanelRootState> = {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
