import { API_BASE_URL } from "@/helpers/envConsts";
import { getAxiosInstance } from "@/shared/services/axios-base";
import { AxiosInstance } from "axios";
import { Store } from "vuex";
import { Member, RegistrationResponse, RegistrationStatus } from "./types";

class ApiService {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  // GEMA endpoints
  public async me() {
    return await this.axios
      .get<RegistrationResponse>("/registrations/me")
      .then((res) => res.data);
  }

  public async representedBy(userId: string) {
    return await this.axios
      .get<RegistrationResponse[]>(`/registrations/represented-by/${userId}`)
      .then((res) => res.data);
  }

  /**  Uses data in RegistrationResponse objects to create a new Member */
  regResponseToMember(response: RegistrationResponse): Member {
    const m: Member = {
      ...response.member,
      attendanceType: response.registration?.attendanceType || null,
      attendanceTypePending:
        response.registration?.attendanceTypePending || null,
      registrationStatus:
        response.registration?.status || RegistrationStatus.NOT_REGISTERED,
      userId: response.registration?.userId || "",
      registration: response.registration || null,
    };

    return m;
  }
}

let gemaApi: ApiService;

function initGemaApi(store: Store<unknown>) {
  if (gemaApi) return gemaApi;

  if (!API_BASE_URL) {
    throw Error("Missing env var - BASE_URL_CHAT");
  }

  const axiosInstance = getAxiosInstance(
    {
      baseURL: API_BASE_URL,
      responseType: "json",
    },
    store
  );

  gemaApi = new ApiService(axiosInstance);
  return axiosInstance;
}

export { gemaApi, initGemaApi };
