import { render, staticRenderFns } from "./SVSelect.vue?vue&type=template&id=a716099c&scoped=true&"
import script from "./SVSelect.vue?vue&type=script&lang=ts&"
export * from "./SVSelect.vue?vue&type=script&lang=ts&"
import style0 from "./SVSelect.vue?vue&type=style&index=0&id=a716099c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a716099c",
  null
  
)

export default component.exports