import { AppRootState } from "@/shared/@types/app";
import { apiService } from "@/shared/services/api-service";
import { getImageMimeType } from "@/shared/utils/MimeType";
import { AxiosError } from "axios";
import Vue from "vue";
import { ActionTree } from "vuex";
import { EditRequest, User, UserState, UserUpdatePayload } from "../types";

// TODO: enable upload again
// async function uploadImage(img: File): Promise<string | null> {
//   if (img) {
//     const formData = new FormData();
//     formData.append("image", img);
//     const resp = await UploadService.post<string>("/upload/profile", formData);
//     return resp.data;
//   }
//   return null;
// }

export const actions: ActionTree<UserState, AppRootState> = {
  async fetch(context): Promise<User | null> {
    try {
      const user = await apiService.getUserInfo();

      context.commit("setUser", user);
      Vue.$log.debug("User fetch success", user);
      return user;
    } catch (error) {
      Vue.$log.debug("User fetch error", error);
      context.dispatch(
        "tracking/logError",
        {
          ea: "error",
          ec: "userinfo",
          ed: error,
        },
        { root: true }
      );
    }
    return null;
  },
  async update(context, updatePayload: UserUpdatePayload): Promise<void> {
    context.commit("setUpdateError", false);
    const user = context.state.user;
    const editRequest: EditRequest = {
      avatar: null,
      badge: null,
      color: updatePayload.color,
    };
    if (updatePayload.displayName !== user?.editRequest?.displayName) {
      editRequest.displayName = updatePayload.displayName;
    }
    if (updatePayload.avatarUrl && updatePayload.avatarUrl !== "") {
      editRequest.avatar = {
        src: updatePayload.avatarUrl,
        mimeType: getImageMimeType(updatePayload.avatarUrl),
      };
    }
    // else if (updatePayload.imageFile) {
    //   const url = await uploadImage(updatePayload.imageFile);
    //   if (url) {
    //     editRequest.avatar = {
    //       src: url,
    //       mimeType: getImageMimeType(url)
    //     };
    //   }
    // }
    if (updatePayload.badgeUrl && updatePayload.badgeUrl !== "") {
      editRequest.badge = {
        src: updatePayload.badgeUrl,
        mimeType: getImageMimeType(updatePayload.badgeUrl),
      };
    }
    if (updatePayload.badgeUrl === "") {
      editRequest.badge = {
        src: "",
        mimeType: "",
      };
    }
    try {
      const user = await apiService.updateUserInfo(editRequest);
      context.dispatch("auth/refresh", null, { root: true });
      context.commit("setUser", user);
      Vue.$log.debug("User update success", user);
      context.dispatch(
        "tracking/logEvent",
        {
          ea: "update",
          ec: "userinfo",
        },
        { root: true }
      );
    } catch (error) {
      if ((error as AxiosError).response?.status === 400) {
        context.commit("setUpdateError", true);
      }
      Vue.$log.debug("User update error", error);
      context.dispatch(
        "tracking/logError",
        {
          ea: "error",
          ec: "userinfo",
          ed: error,
        },
        { root: true }
      );
    }
  },
};
