import { Action, ActionContext } from "vuex";
import {
  GemaAction,
  GemaMutation,
  GemaState,
  Member,
  RegistrationResponse,
} from "../types";
import { gemaApi } from "../api";
import { PanelRootState } from "@/panel/store/PanelRootState";
import { UserInfo } from "@/shared/user/types";
import { USER_NAMESPACE } from "@/shared/user/store";

const setMember = async (
  context: ActionContext<GemaState, PanelRootState>,
  member: Member
): Promise<void> => {
  context.commit(GemaMutation.SetMember, member);
};

const fetchMe = async (
  context: ActionContext<GemaState, PanelRootState>
): Promise<RegistrationResponse> => {
  const member = await gemaApi.me();
  context.dispatch(GemaAction.SetMember, member);
  return member;
};

const fetchRepresentatedMembers = async (
  context: ActionContext<GemaState, PanelRootState>
): Promise<RegistrationResponse[]> => {
  const user: UserInfo = context.rootGetters[`${USER_NAMESPACE}/userInfo`];
  const representedMembers = await gemaApi.representedBy(user.userId);
  context.commit(GemaMutation.SetRepresentatedMembers, representedMembers);

  return representedMembers;
};

const clear = async ({
  commit,
  state,
}: ActionContext<GemaState, PanelRootState>): Promise<void> => {
  console.log("Clearing...");
  commit(GemaMutation.SetRepresentatedMembers, []);
  console.log("Representations: ", state.representedMembers);
};

export const actions: Record<GemaAction, Action<GemaState, PanelRootState>> = {
  [GemaAction.FetchMe]: fetchMe,
  [GemaAction.SetMember]: setMember,
  [GemaAction.FetchRepresentatedMembers]: fetchRepresentatedMembers,
  [GemaAction.Clear]: clear,
};
