


















































import { isDark, hexToRGB } from "@/shared/utils";
import { Vue, Component, Prop } from "vue-property-decorator";
import { RegistrationResponse } from "../gema/types";
import SVSelect from "./SVSelect.vue";
import { SVQuestion, SVVotePayload, SVGetter } from "./types";
import { Getter } from "vuex-class";
import { SV_NAMESPACE } from "./store";

@Component({
  components: {
    SVSelect,
  },
})
export default class SecureVotingQuestion extends Vue {
  @Prop({ required: true }) question!: SVQuestion;
  @Prop({ required: true }) representedUsers!: RegistrationResponse[];

  @Getter(`${SV_NAMESPACE}/${SVGetter.AvailableVotes}`)
  availableVotes!: number;

  selectedAnswerIds: string[] = []; // TODO: Confirm expected data to be encrypted
  isValidatingPhase = false;
  showAbstainNotice = false;
  voteSubmittedNotice = false;
  voteSubmitted = false;
  voteSubmittedTimer = 0;

  get brandTextColorLight(): boolean {
    const col = this.$vuetify.theme.currentTheme.brand?.toString();
    return !col || isDark(hexToRGB(col));
  }

  submitVotes() {
    if (!this.isValidatingPhase) {
      if (this.selectedAnswerIds.length === 0) {
        this.showAbstainNotice = true;
      }

      this.isValidatingPhase = true;
      return;
    }

    let payload: SVVotePayload[];

    if (this.representedUsers.length) {
      payload = this.getMultiVotePayload();
    } else {
      payload = [
        {
          questionId: this.question.id,
          answerIds: this.selectedAnswerIds,
        },
      ];
    }

    this.voteSubmitted = true;
    this.$emit("submitVote", payload);
  }

  onSelectedAnswerChange(selectedAnswerIds: string[]) {
    this.selectedAnswerIds = selectedAnswerIds;
    this.setInitFlags();
  }

  setInitFlags() {
    this.isValidatingPhase = false; // Revert to picking phase
    this.showAbstainNotice = false; // Revert to picking phase
  }

  // Distributes votes evenly into SVVotePayload objects. Makes assumptions on abstained votes as we don't know which vote is tied to which represented registration.
  getMultiVotePayload(): SVVotePayload[] {
    const answersPerUser = this.question.maximumVotes;

    const groupedAnswerIds = this.selectedAnswerIds.reduce(
      (acc: Record<string, number>, answerId) => {
        if (acc[answerId]) acc[answerId]++;
        else acc[answerId] = 1;
        return acc;
      },
      {}
    );

    if (
      Object.values(groupedAnswerIds).some((num) => num > this.availableVotes)
    )
      throw Error("More selected answer id's per user than allowed!");

    const votes: Array<{ questionId: string; answerIds: string[] }> = [];

    for (let i = 0; i < answersPerUser; i++) {
      for (let j = 0; j < this.availableVotes; j++) {
        if (!votes[j])
          votes[j] = { questionId: this.question.id, answerIds: [] };
        for (const [key, val] of Object.entries(groupedAnswerIds)) {
          if (val > 0 && votes[j]?.answerIds?.length <= i) {
            groupedAnswerIds[key]--;
            votes[j].answerIds.push(key);
          }
        }
      }
    }

    return votes;
  }

  fuckYouVue() {
    this.$emit("success");
  }
}
