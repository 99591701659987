










import { Vue, Component, Prop } from "vue-property-decorator";
import { Resource } from "../types";
import {
  getResourceFilename,
  getResourceFileTypeLabel,
} from "@/panel/resources/helpers";

@Component({})
export default class ResourceInfo extends Vue {
  @Prop({ required: true }) resource!: Resource;

  get fileName(): string {
    return getResourceFilename(this.resource);
  }

  get fileTypeLabel() {
    const mimeType = this.resource.upManMedia.mimeType;
    return getResourceFileTypeLabel(mimeType);
  }

  get fileSizeText(): string {
    const fileSize = this.resource.upManMedia.fileSize;
    return Math.max(0.01, this.bytesToMegaBytes(fileSize)).toFixed(2) + "MB";
  }

  bytesToMegaBytes(bytes: number) {
    return bytes / (1024 * 1024);
  }
}
