import { Media } from "@/shared/media/types";

export const AD_NAMESPACE = "ad";

export enum AdAction {
  Ad = "Ad",
  VideoAd = "VideoAd",
  ImageAd = "ImageAd",
  AnimatedAd = "AnimatedAd",
  closeAd = "closeAd",
}

export interface AdState {
  ad?: Ad;
}

export interface Ad {
  altText: string;
  clickLink: string;
  closableAfterSeconds?: number;
  id: string;
  name: string;

  // Note: needs to be set on the client side when we receive a ScheduledEvent { timestamp, payload }
  timestamp?: number;
}

export interface ImageAd extends Ad {
  image: Media;
}

export interface VideoAd extends Ad {
  video: Media;
}

export enum AdType {
  image = "image",
  video = "video",
}
