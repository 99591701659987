/* eslint-disable @typescript-eslint/no-empty-interface */
export interface TrackingState {}

export enum TrackingParameter {
  TIMESTAMP = "ti",
  TENANT_ID = "tid",
  BROADCAST_ID = "bid",
  SESSION_ID = "sid",
  OVERLAY_SESSION_ID = "osid",
  HIT_TYPE = "ht",
  APP_TYPE = "at",
  EVENT_ACTION = "ea",
  EVENT_CATEGORY = "ec",
  EVENT_LABEL = "el",
  EVENT_DATA = "ed",
  EVENT_VALUE = "ev",
  DOCUMENT_PATH = "dp",
  DOCUMENT_TITLE = "dt",
  DOCUMENT_HOST = "dh",
  DOCUMENT_LOCATION = "dl",
}

export enum HitType {
  EVENT = "event",
  PAGEVIEW = "pageview",
}
