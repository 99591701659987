export interface GemaState {
  member: RegistrationResponse | null;
  representedMembers: RegistrationResponse[];

  svSettings: SVSettings | null;
  registrationSettings: RegistrationSettings | null;
}

export enum GemaAction {
  SetMember = "SetMember",
  FetchMe = "FetchMe",
  FetchRepresentatedMembers = "FetchRepresentatedMembers",
  // FetchSVSettings = "FetchSVSettings",
  // FetchRegistrationSettings = "FetchRegistrationSettings",
  Clear = "Clear",
}

export enum GemaMutation {
  SetMember = "SetMember",
  SetSVSettings = "SetSVSettings",
  SetRegistrationSettings = "SetRegistrationSettings",
  SetRepresentatedMembers = "SetRepresentatedMembers",
}

export enum GemaGetter {
  Member = "Member",
  MemberAttendance = "MemberAttendance",
  IsMT = "MemberMT",
  IsNMR = "IsNMR",
  SVSettings = "SVSettings",
  RegistrationSettings = "RegistrationSettings",
  RepresentatedMembers = "RepresentatedMembers",
}

export interface Member {
  attendanceType: Attendance | null;
  attendanceTypePending: Attendance | null;
  badgeId: string | null;
  birthDate: string | null;
  delegate: Delegate | null;
  email: string | null;
  firstName: string;
  group: OccupationalGroup;
  kaCity: string | null;
  kaCountryIso: string | null;
  kaEpost: string | null;
  kaPlz: string | null;
  kaStreet: string | null;
  kooptiert: boolean | null;
  lastName: string;
  memberNumber: string;
  memberStatus: MemberStatus;
  nonMemberRepresentative: boolean;
  notARealMember: boolean;
  publisherName: string | null;
  publisherWithoutVotingPermission: boolean | null; // 'VoS'
  representationCodeHash: string | null;
  registration: RegistrationInfo | null;
  registrationStatus: RegistrationStatus | null;
  rn: boolean | null;
  serialNumber: number;
  sideEventAttendance: SideEvents | null;
  userId: string;
}

export type OccupationalGroup = "K" | "V" | "T" | "MT" | "G" | "SV";
export type Delegate = "D" | "SD" | null;
export type MemberStatus = "A" | "O" | "G";

export enum Attendance {
  IN_PERSON = "IN_PERSON",
  DIGITAL = "DIGITAL",
  PRE_VOTE = "PRE_VOTE",
  BY_REPRESENTATIVE_DIGITAL = "BY_REPRESENTATIVE_DIGITAL",
  BY_REPRESENTATIVE_IN_PERSON = "BY_REPRESENTATIVE_IN_PERSON",
}

export interface Representation {
  noVotingPermission: boolean;
  representativeUserId: string;
  representedUserId: string;
  representedMemberId: string;
  representativeAttendanceType: Attendance;
  representativeBirthDate: string;
  function: RepFunction;
  conflictOfInterestIsUser: boolean;
  conflictOfInterestIsUserRepresentsInterests: boolean;
  conflictOfInterestComment: string;
  type: RepresentationType;
  memberRepresentation: boolean;
  representativeMemberId: string;
  representativeFirstName: string;
  representativeLastName: string;
  representativeEmail: string;
}

export interface SVSettings {
  id: string;
  tenantId: string;
  preVotingEnabled: boolean;
  liveVotingEnabled: boolean;
}

export interface RegistrationSettings {
  id: string;
  tenantId: string;
  personalEnabled: boolean;
  preVoteEnabled: boolean;
  representationEnabled: boolean;
  sideEventsEnabled: boolean;
}

export enum RepresentationSelection {
  URHEBER_ORDENTLICH_IN_PERSON = "URHEBER_ORDENTLICH_IN_PERSON",
  VERLAG_ORDENTLICH_IN_PERSON = "VERLAG_ORDENTLICH_IN_PERSON",
  VERLAG_ORDENTLICH_DIGITAL = "VERLAG_ORDENTLICH_DIGITAL",
  VERLAG_AUSSERORDENTLICH_IN_PERSON = "VERLAG_AUSSERORDENTLICH_IN_PERSON",
  VERLAG_AUSSERORDENTLICH_DIGITAL = "VERLAG_AUSSERORDENTLICH_DIGITAL",
}

// ------
export interface RegistrationResponse {
  member: Member;
  registration?: RegistrationInfo;
}

export interface RegistrationInfo {
  id: string | null;
  userId: string | null;
  attendanceType: Attendance | null;
  attendanceTypePending: Attendance | null;
  days: "ONE" | "TWO_AND_THREE" | null;
  status: RegistrationStatus | null;
  representation: Representation | null;
  createdTimestamp: string | null; //Instant
  lastEditedTimestamp: string | null; //Instant
  termsAcceptedTimestamp: string | null; //Instant
  lastEditor: string | null;
}

export enum RegistrationStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
  REPRESENTING = "REPRESENTING",
  NOT_REGISTERED = "NOT_REGISTERED",
}

export enum AttendanceType {
  IN_PERSON = "IN_PERSON",
  DIGITAL = "DIGITAL",
  PRE_VOTE = "PRE_VOTE",
  BY_REPRESENTATIVE_DIGITAL = "BY_REPRESENTATIVE_DIGITAL",
  BY_REPRESENTATIVE_IN_PERSON = "BY_REPRESENTATIVE_IN_PERSON",
}

type InfoEvent = number;
export interface SideEvents {
  memberFestival: boolean;
  technicalTraining: boolean;
  curiaConsultationHour: boolean;
  occupationalGroupGathering: boolean;
  mtChosenOccupationalGroup: OccupationalGroup | null;
  infoEvent0: InfoEvent | null;
  infoEvent1: InfoEvent | null;
  infoEvent2: InfoEvent | null;
  infoEvent3: InfoEvent | null;
  email: string;
}

export interface SVKeys {
  encodedEncryptedPrivateKey: string;
  encodedEncryptedPublicKeyId: string;
  salt: string;
  encodedPrivateKeyIv: string;
  encodedPublicKeyIdIv: string;
}

export interface SVVote {
  questionId: string;
  answerIds: string[];
}

export enum RepresentationType {
  REP_IN_PERSON,
  REP_DIGITAL,
  EX_IN_PERSON,
  EX_DIGITAL,
}

export enum RepFunction {
  PROXY = "PROXY",
  OWNER = "OWNER",
}
