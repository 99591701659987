

















































import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { VForm } from "@/shared/@types/generic";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Config } from "@/shared/config/types";
import { QA_NAMESPACE } from "../../store";
import {
  QAAction,
  QuestionDto,
  QuestionType,
  QASnackBarError,
} from "../../types";
import { isDark, hexToRGB } from "@/shared/utils";

@Component({})
export default class GemaQuestionInput extends Vue {
  @Prop({ required: true }) category!: string;

  @Action(QAAction.CreateQuestion, { namespace: QA_NAMESPACE })
  saveQuestion!: (payload: QuestionDto) => Promise<void>;

  @Getter("config", { namespace: CONFIG_NAMESPACE })
  config!: Config;

  @Ref("qaForm") form!: VForm;

  userInput = "";
  userInputRules = [this.stringLength(1, 750)];
  valid = true;

  successDialog = false;

  stringLength(minLen: number, maxLen?: number) {
    return (v: string) => {
      if (!v) return true;

      if (maxLen) {
        return v.length >= minLen && v.length <= maxLen
          ? true
          : this.$t("formError.stringLengthMax", { minLen, maxLen });
      } else {
        return v.length >= minLen
          ? true
          : this.$t("formError.stringLengthMin", { minLen });
      }
    };
  }

  async submitQuestion() {
    if (!this.form.validate()) return;
    if (!this.userInput) return;

    try {
      await this.saveQuestion({
        content: this.userInput.trim(),
        note: "",
        type: QuestionType.TEXT,
        category: this.category,
      });
      this.userInput = "";
      this.form.resetValidation();

      this.successDialog = true;
    } catch (error) {
      this.emitSnackbarError({
        message: this.$t("formError.unknown").toString(),
        color: "error",
      });
    }
  }

  emitSnackbarError(payload: QASnackBarError) {
    this.$emit("error", payload);
  }

  get brandTextColorLight(): boolean {
    const col = this.$vuetify.theme.currentTheme.brand?.toString();
    return !col || isDark(hexToRGB(col));
  }
}
