import { OccupationalGroup, MemberStatus } from "@/panel/gema/types";

export interface SVState {
  loading: boolean;
  // todo remove
  questions: SVQuestion[];
  activeQuestion: SVQuestion | null;
  // id's of questions already finished
  questionsDone: string[];
  hash?: string;
  verySecureStorage?: {
    privKey: string;
    pubKey: string;
  };
  availableVotes: number;
}

export interface SVQuestion {
  id: string;
  tenantId: string;
  targetGroup: OccupationalGroup;
  targetMemberStatus: MemberStatus;
  type: SVQuestionType;
  title: string;
  link: string;
  question: string;
  answers: SVAnswer[];
  minimumVotes: number;
  maximumVotes: number;
  status: QuestionStatus;
}

export enum SVQuestionType {
  Poll = "POLL",
}

export enum SVGetter {
  ActiveQuestion = "ActiveQuestion",
  Loading = "Loading",
  Hash = "Hash",
  SecureStorage = "SecureStorage",
  LiveQuestion = "LiveQuestion",
  AvailableVotes = "AvailableVotes",
}

export enum SVMutation {
  SetLoading = "SetLoading",
  SetActiveQuestion = "SetActiveQuestion",
  SetQuestions = "SetQuestions",
  AddQuestion = "AddQuestion",
  SetQuestion = "SetQuestion",
  RemoveQuestion = "RemoveQuestion",
  SetHash = "SetHash",
  SetSecureStorage = "SetSecureStorage",
  AddQuestionDone = "AddQuestionDone",
  SetAvailableVotes = "SetAvailableVotes",
}

export enum SVAction {
  GetActiveQuestion = "GetActiveQuestion",
  SetActiveQuestion = "SetActiveQuestion",
  SubmitVote = "SubmitVote",
  PrepareCrypto = "PrepareCrypto",
  SetHash = "SetHash",
  SetSecureStorage = "SetSecureStorage",
  HandleQuestionChange = "HandleQuestionChange",
  GetAvailableVotes = "GetAvailableVotes",
}

export interface SVVotePayload {
  questionId: string;
  answerIds: string[];
}

export type QuestionStatus =
  | "PREPARING"
  | "PRE_VOTE"
  | "HIDDEN"
  | "LIVE"
  | "CLOSED";

export interface SVQuestionVotesResponse {
  id: string;
  tenantId: string;
  broadcastIds: string[];
  targetMemberStatus: MemberStatus;
  targetGroup: OccupationalGroup;
  type: "POLL" | "ELECTION";
  title: string;
  link: string;
  question: string;
  minimumVotes: number;
  maximumVotes: number;
  status: "PREPARING" | "PRE_VOTE" | "HIDDEN" | "LIVE" | "CLOSED";
  uniqueVotes: number;
  totalVotes: number;
  answers: SVAnswer[];
}

export interface SVAnswer {
  answerId: string;
  value: string;
}

// Map Nchan events to a dedicated store action
export const SVNchanEvents: Record<string, SVAction> = {
  SV_QUESTION_STATUS_CHANGED: SVAction.HandleQuestionChange,
};

export interface SVResult {
  id: string;
  title: string;
  question: string;
  type: SVQuestionType;
  answers: SVAnswerVotesResponse[];
  uniqueVotes: number;
  uniqueValidVotes: number;
  uniqueAbstainedVotes: number;
}

export interface SVAnswerVotesResponse {
  answerId: string;
  value: string;
  voteCount: number;
}

export enum SVQuestionType {
  POLL = "POLL", // => "Abstimmung"
  ELECTION = "ELECTION", // => "Wahl"
}

export interface AvailableVotesResponse {
  numAvailableVotes: number;
}
