














































import { USER_NAMESPACE } from "@/shared/user/store";
import humanizeDuration from "humanize-duration";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  Poll,
  PollAction,
  PollAnswer,
  PollResultVisibility,
  PollVote,
} from "../../types";
import { POLL_NAMESPACE } from "../../store";
import PollAnswers from "./shared-components/PollAnswers.vue";
import PollResults from "./shared-components/PollResults.vue";
import PollModeratorActions from "./shared-components/PollModeratorActions.vue";
import PollQuestion from "./shared-components/PollQuestion.vue";

@Component({
  components: {
    PollQuestion,
    PollAnswers,
    PollModeratorActions,
    PollResults,
  },
})
export default class DefaultPoll extends Vue {
  @Prop({ required: true }) poll!: Poll;
  @Prop({ required: true }) isActive!: boolean;
  @Prop({ required: true }) voteInProgress!: boolean;

  @Action(PollAction.GET_USERS_VOTES, { namespace: POLL_NAMESPACE })
  getUsersVotes!: (pollId: string) => Promise<void>;

  @Getter("userInfoIsValid", { namespace: USER_NAMESPACE })
  readonly userInfoIsValid!: boolean;
  @Getter("userVotes", { namespace: POLL_NAMESPACE })
  readonly allUserVotes!: Record<string, PollVote[]>;

  isExpanded = false;
  timeLeftTimeout = 0;
  timeLeft = 0;

  pendingPollResultsText(): string {
    switch (this.poll.options.resultVisibility) {
      case PollResultVisibility.ON_DEMAND:
        return (
          this.$t("polls.answerReceived") + " " + this.$t("polls.adminPublish")
        );
      case PollResultVisibility.ON_POLL_END:
        return this.$t("polls.answerReceived") + " " + this.$t("polls.publish");
      default:
        return this.$t("polls.answerReceived").toString();
    }
  }

  async created() {
    if (!this.allUserVotes[this.poll.id]) {
      await this.getUsersVotes(this.poll.id);
    }
    this.calcTimeLeft();
  }

  destroyed() {
    clearTimeout(this.timeLeftTimeout);
  }

  get answerState(): string {
    if (this.answered) {
      return this.$t("polls.answered").toString();
    } else if (!this.isActive) {
      return this.$t("polls.missed").toString();
    } else {
      return this.$t("polls.open").toString();
    }
  }

  get pollVotes(): PollVote[] {
    return this.allUserVotes[this.poll.id] || [];
  }

  get answered(): boolean {
    return this.pollVotes.length >= this.poll.options.votesPerPerson;
  }

  get answers(): PollAnswer[] {
    return this.poll.answers;
  }

  get disabled(): boolean {
    return this.voteInProgress || !this.userInfoIsValid;
  }

  get timeLeftState(): string {
    if (this.isActive && this.timeLeft > 0) {
      return (
        humanizeDuration(this.timeLeft, {
          units: ["h", "m"],
          round: true,
          language: this.$i18n.locale,
        }) +
        " " +
        this.$t("polls.left")
      );
    }
    return this.$t("polls.ended").toString();
  }

  toggle(): void {
    this.isExpanded = !this.isExpanded;
  }

  calcTimeLeft(): void {
    if (this.isActive) {
      this.timeLeft = new Date(this.poll.endDate).getTime() - Date.now();
      if (this.timeLeft > 0) {
        this.timeLeftTimeout = setTimeout(this.calcTimeLeft, 60000);
        return;
      }
    }
    this.endPoll();
  }

  vote(pollAnswer: PollAnswer) {
    // check if poll became inactive
    if (Date.now() > new Date(this.poll.endDate).getTime()) {
      this.endPoll();
    } else {
      this.$emit("vote", pollAnswer);
    }
  }

  endPoll() {
    this.$emit("endPoll");
    this.clearTimeLeft();
  }

  @Watch("isActive")
  clearTimeLeft() {
    clearTimeout(this.timeLeftTimeout);
  }
}
