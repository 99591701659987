import { PanelRootState } from "@/panel/store/PanelRootState";
import Vue from "vue";
import Vuex, { Module } from "vuex";
import { SVState } from "../types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

Vue.use(Vuex);

export const SV_NAMESPACE = "secure-voting";

export const defaultState: SVState = {
  loading: false,
  activeQuestion: null,
  questions: [],
  questionsDone: [],
  availableVotes: 0,
};

export const sv: Module<SVState, PanelRootState> = {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
