import { Mutation } from "vuex";
import { SVMutation, SVQuestion, SVState } from "../types";

export const mutations: Record<SVMutation, Mutation<SVState>> = {
  [SVMutation.SetActiveQuestion](state, question: SVQuestion | null) {
    state.activeQuestion = question;
  },
  [SVMutation.SetHash](state, hash: string) {
    state.hash = hash;
  },
  [SVMutation.SetSecureStorage](
    state,
    payload: { privKey: string; pubKey: string }
  ) {
    state.verySecureStorage = payload;
  },
  [SVMutation.AddQuestion](state, question: SVQuestion) {
    if (!state.questions.find((q) => q.id === question.id))
      state.questions.push(question);
  },
  [SVMutation.AddQuestionDone](state, questionId) {
    if (!state.questionsDone.includes(questionId))
      state.questionsDone.push(questionId);
  },
  [SVMutation.RemoveQuestion](state, question: SVQuestion) {
    state.questions = state.questions.filter((q) => q.id !== question.id);
  },
  [SVMutation.SetQuestions](state, questions: SVQuestion[]) {
    state.questions = questions;
  },
  [SVMutation.SetLoading](state, boolVal: boolean) {
    state.loading = boolVal;
  },
  [SVMutation.SetQuestion](
    state,
    payload: { questionId: string; question: SVQuestion }
  ) {
    const idx = state.questions.findIndex((q) => q.id === payload.questionId);
    if (idx >= 0) state.questions[idx] = payload.question;
  },
  [SVMutation.SetAvailableVotes](state, votes: number) {
    state.availableVotes = votes;
  },
};
