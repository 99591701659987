import Vue from "vue";
import { MutationTree } from "vuex";
import { MerchState, ShopCategory, ShopDisclaimer, ShopItem } from "../types";

export const mutations: MutationTree<MerchState> = {
  // merch(state, newMerch: Array<Merch>) {
  //   Vue.set(state, "merch", newMerch);
  // },
  setCategories(state, value: ShopCategory[]) {
    Vue.set(state, "shopCategories", value);
  },
  setShopItems(state, value: ShopItem[]) {
    Vue.set(state, "shopItems", value);
  },
  setDisclaimers(state, value: ShopDisclaimer[]) {
    state.disclaimers = value;
  },
};
