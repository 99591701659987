import Vue from "vue";
import { Mutation } from "vuex";
import {
  GemaMutation,
  GemaState,
  Member,
  RegistrationResponse,
  RegistrationSettings,
  SVSettings,
} from "../types";

export const mutations: Record<GemaMutation, Mutation<GemaState>> = {
  [GemaMutation.SetMember](state, member: RegistrationResponse) {
    Vue.set(state, "member", member);
  },
  [GemaMutation.SetSVSettings](state, settings: SVSettings) {
    Vue.set(state, "svSettings", settings);
  },
  [GemaMutation.SetRegistrationSettings](
    state,
    settings: RegistrationSettings
  ) {
    Vue.set(state, "registrationSettings", settings);
  },
  [GemaMutation.SetRepresentatedMembers](
    state,
    members: RegistrationResponse[]
  ) {
    Vue.set(state, "representedMembers", members);
  },
};
