import { AppRootState } from "@/shared/@types/app";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { AppConfig } from "@/shared/config/types";
import Vue from "vue";
import { ActionTree } from "vuex";
import { trackingService } from "../tracking-service";
import { TrackingParameter, TrackingState } from "../types";

export const actions: ActionTree<TrackingState, AppRootState> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logEvent(context, params: any): void {
    const appConfig: AppConfig =
      context.rootGetters[CONFIG_NAMESPACE + "/appConfig"];
    if (appConfig) {
      params[TrackingParameter.APP_TYPE] = appConfig.platform;
      params[TrackingParameter.BROADCAST_ID] = appConfig.broadcastId;
      params[TrackingParameter.TENANT_ID] = appConfig.tenant;
    }
    trackingService.logEvent(params);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logError(context, params: any): void {
    if (params.ed) {
      try {
        params.ed = JSON.stringify(params.ed);
        context.dispatch("logEvent", params);
      } catch (error) {
        Vue.$log.debug("Error log", error);
      }
    } else {
      context.dispatch("logEvent", params);
    }
  },
};
