

























import { Vue, Component, Ref } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { QA_NAMESPACE } from "../store";
import { QAAction, QASnackBarError, QuestionDto, QuestionType } from "../types";
import { VForm } from "@/shared/@types/generic";
import VideoCallButton from "./VideoCallButton.vue";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Config, Feature } from "@/shared/config/types";

@Component({
  components: {
    VideoCallButton,
  },
})
export default class QuestionInput extends Vue {
  @Action(QAAction.CreateQuestion, { namespace: QA_NAMESPACE })
  saveQuestion!: (payload: QuestionDto) => Promise<void>;

  @Getter("config", { namespace: CONFIG_NAMESPACE })
  config!: Config;

  @Ref("qaForm") form!: VForm;

  userInput = "";
  userInputRules = [this.stringLength(1, 200)];
  valid = true;

  stringLength(minLen: number, maxLen?: number) {
    return (v: string) => {
      if (!v) return true;

      if (maxLen) {
        return v.length >= minLen && v.length <= maxLen
          ? true
          : this.$t("formError.stringLengthMax", { minLen, maxLen });
      } else {
        return v.length >= minLen
          ? true
          : this.$t("formError.stringLengthMin", { minLen });
      }
    };
  }

  async submitQuestion() {
    if (!this.form.validate()) return;
    if (!this.userInput) return;

    try {
      await this.saveQuestion({
        content: this.userInput.trim(),
        note: "",
        type: QuestionType.TEXT,
      });
      this.userInput = "";
      this.form.resetValidation();
    } catch (error) {
      this.emitSnackbarError({
        message: this.$t("formError.unknown").toString(),
        color: "error",
      });
    }
  }

  emitSnackbarError(payload: QASnackBarError) {
    this.$emit("error", payload);
  }

  get videoConferencingEnabled(): boolean {
    return this.config.features.enabled.includes(Feature.VIDEO_CONFERENCING);
  }
}
