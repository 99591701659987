




import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Vue, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { isGema } from "@/shared/utils";
//import GemaQAPanel from "./gema/GemaQAPanel.vue";
import GemaQAAntrag from "./gema/GemaQAAntrag.vue";
import QAPanel from "./QAPanel.vue";

type ComponentTypes = "GemaQAAntrag" | "QAPanel"; //GemaQAPanel

@Component({
  components: {
    QAPanel,
    GemaQAAntrag,
    //GemaQAPanel
  },
})
export default class QAPage extends Vue {
  @Getter(`${CONFIG_NAMESPACE}/tenantName`) tenantName!: string;

  get componentName(): ComponentTypes {
    if (isGema(this.tenantName)) return "GemaQAAntrag"; //GemaQAPanel
    return "QAPanel";
  }
}
