import {
  AvailableVotesResponse,
  SVVotePayload,
} from "./../../panel/secure-voting/types";
import { API_BASE_URL } from "@/helpers/envConsts";
import { RegistrationResponse, SVKeys, SVVote } from "@/panel/gema/types";
import { ShopCategory, ShopDisclaimer, ShopItem } from "@/panel/merch/types";
import { NavigationItem, NavigationResponse } from "@/panel/navigation/types";
import { Resource } from "@/panel/resources/types";
import { QuestionStatus, SVQuestion } from "@/panel/secure-voting/types";
import { AxiosInstance, AxiosResponse } from "axios";
import { Store } from "vuex";
import {
  Broadcast,
  FeatureResponse,
  GeneralSettings,
  TenantResponse,
} from "../config/types";
import { ThemeConfig } from "../theme/types";
import { EditRequest, User } from "../user/types";
import { getAxiosInstance } from "./axios-base";
import CryptoJS from "crypto-js";

class ApiService {
  private shopEndpoint = "/shop";
  private userinfoEndpoint = "/userinfo";
  private settingsEndpoint = "/settings";
  private broadcastsEndpoint = "/broadcast";
  private tenantEndpoint = "/tenant";
  private resourcesEndpoint = "/file-archive";
  private secureVotingEndpoint = "/secure-voting";

  // TODO: Make this private once, spect8chat supports the class
  public axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  // UserInfo

  async getUserInfo(): Promise<User> {
    return this.axios.get(this.userinfoEndpoint).then((res) => res.data);
  }

  async updateUserInfo(editRequest: EditRequest): Promise<User> {
    return this.axios
      .put(this.userinfoEndpoint, editRequest)
      .then((res) => res.data);
  }

  // Shop

  async getShopCategories(): Promise<ShopCategory[]> {
    return this.axios
      .get(this.shopEndpoint + "/category")
      .then((res) => res.data);
  }

  async getShopItemsByCategoryId(categoryId: string): Promise<ShopItem[]> {
    return this.axios
      .get(this.shopEndpoint + `/item/${categoryId}`)
      .then((res) => res.data);
  }

  async getShopDisclaimers(): Promise<ShopDisclaimer[]> {
    return this.axios
      .get(this.shopEndpoint + "/disclaimer")
      .then((res) => res.data);
  }

  // Settings

  async getNavigationTabs(
    broadcastId: string | null
  ): Promise<NavigationItem[]> {
    return this.axios
      .get<NavigationResponse>(this.settingsEndpoint + "/tab/", {
        params: { broadcastId: broadcastId },
      })
      .then((res) => res.data.tabs);
  }

  async getFeatures(broadcastId?: string): Promise<FeatureResponse> {
    let promise!: Promise<AxiosResponse<FeatureResponse>>;
    const endpoint = this.settingsEndpoint + "/feature";

    if (broadcastId) {
      promise = this.axios.get(endpoint, {
        params: { broadcastId: broadcastId },
      });
    } else {
      promise = this.axios.get(endpoint);
    }

    return await promise.then((res) => res.data);
  }

  async getThemeConfig(tenantId: string): Promise<ThemeConfig> {
    return this.axios
      .get(this.settingsEndpoint + `/theme/${tenantId}`)
      .then((res) => res.data);
  }

  fetchSettings(broadcastId?: string): Promise<GeneralSettings> {
    return this.axios
      .get<GeneralSettings>("/settings/chat", {
        params: { broadcastId },
      })
      .then((res) => res.data);
  }

  // Broadcasts
  async getOrCreateBroadcast(broadcastName: string): Promise<Broadcast> {
    return this.axios
      .post(this.broadcastsEndpoint, null, {
        params: { broadcastName },
      })
      .then((res) => res.data);
  }

  async getBroadcastByName(broadcastName: string): Promise<Broadcast> {
    return this.axios
      .get(this.broadcastsEndpoint + `/${broadcastName}`)
      .then((res) => res.data);
  }

  // Tenant

  async getTenantById(tenantId: string): Promise<TenantResponse> {
    return this.axios
      .get(this.tenantEndpoint + `?id=${tenantId}`)
      .then((res) => res.data);
  }

  // Resources

  async getResources(
    tenantWide: boolean,
    broadcastId?: string
  ): Promise<Resource[]> {
    const params: { broadcastId?: string; tenantWide: boolean } = {
      tenantWide,
    };

    if (broadcastId) {
      params["broadcastId"] = broadcastId;
    }
    return this.axios
      .get(this.resourcesEndpoint, { params })
      .then((res) => res.data);
  }

  // GEMA endpoints
  async getMemberRegistration() {
    return await this.axios
      .get<RegistrationResponse>("/registrations/me")
      .then((res) => res.data);
  }

  async getRepresentedRegistrations(
    userId: string
  ): Promise<RegistrationResponse[]> {
    return await this.axios
      .get<RegistrationResponse[]>(`/registrations/represented-by/${userId}`)
      .then((res) => res.data);
  }

  //== Secure Voting
  async getSVQuestions(questionStatus: QuestionStatus, broadcastId: string) {
    return await this.axios
      .get<SVQuestion[]>(`${this.secureVotingEndpoint}/questions`, {
        params: { questionStatus, broadcastId },
      })
      .then((res) => res.data);
  }

  async getUserKeys(): Promise<SVKeys> {
    return this.axios
      .get(`${this.secureVotingEndpoint}/keys`)
      .then((res) => res.data);
  }

  async getQuestionVoteCount(questionId: string): Promise<SVKeys> {
    return this.axios
      .get(`${this.secureVotingEndpoint}/questions/${questionId}/votes/count`)
      .then((res) => res.data);
  }

  async getQuestionsVoteCount(): Promise<SVKeys> {
    return this.axios
      .get(`${this.secureVotingEndpoint}/questions/votes/count`)
      .then((res) => res.data);
  }

  public async submitVotes(
    votes: Array<SVVotePayload>,
    questionId: string,
    userPublicKeyId: string,
    broadcastId: string,
    privateKey: string
  ): Promise<void> {
    const encryptedVotes: string[] = await Promise.all(
      votes.map(async (vote: SVVote) => {
        //TODO Use client-side encrypt method instead of endpoint
        const encryptedData = await this.getEncryptedVote(privateKey, vote);
        return encryptedData;
      })
    );

    return this.axios
      .post<void>(
        `${this.secureVotingEndpoint}/questions/${questionId}/votes/`,
        encryptedVotes,
        {
          params: { userPublicKeyId, broadcastId },
        }
      )
      .then((res) => res.data);
  }

  public async submitPreVotes(
    votes: Array<string>,
    userPublicKeyId: string
  ): Promise<void> {
    return this.axios
      .post(`${this.secureVotingEndpoint}/questions/votes`, votes, {
        params: { userPublicKeyId },
      })
      .then((res) => res.data);
  }

  async decodeUserKeys(
    userKeys: SVKeys,
    password: string
  ): Promise<{
    decryptedPrivateKey: string;
    decryptedPublicKey: string;
  }> {
    const decodedPkIv = CryptoJS.enc.Base64.parse(userKeys.encodedPrivateKeyIv);
    const decodedIdIv = CryptoJS.enc.Base64.parse(
      userKeys.encodedPublicKeyIdIv
    );
    const secretKey = password + userKeys.salt;
    const secretKeySHA = CryptoJS.SHA256(secretKey);

    const decryptedPrivateKey = CryptoJS.AES.decrypt(
      userKeys.encodedEncryptedPrivateKey,
      secretKeySHA,
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: decodedPkIv,
      }
    );
    const decryptedPublicKey = CryptoJS.AES.decrypt(
      userKeys.encodedEncryptedPublicKeyId,
      secretKeySHA,
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: decodedIdIv,
      }
    );
    // const hello2 = await this.wordArrayToByteArray(decryptedPublicKey);
    return {
      decryptedPublicKey: decryptedPublicKey.toString(CryptoJS.enc.Utf8),
      decryptedPrivateKey: decryptedPrivateKey.toString(CryptoJS.enc.Utf8),
    };
  }

  async encryptPreVotePayload(
    votes: { questionId: string; answerIds: string[] }[],
    privateKey: string
  ): Promise<string[]> {
    const encryptedVotes: string[] = await Promise.all(
      votes.map(async (vote: SVVote) => {
        //TODO Use client-side encrypt method instead of endpoint
        const encryptedData = await this.getEncryptedVote(privateKey, vote);
        console.log(encryptedData);
        return encryptedData;
      })
    );
    return encryptedVotes;
  }

  async getEncryptedVote(
    privateKey: string,
    vote: { questionId: string; answerIds: string[] }
  ): Promise<string> {
    return this.axios
      .post(
        `${this.secureVotingEndpoint}/keys/encrypt`,
        { contentToEncrypt: vote, encodedPrivateKey: privateKey },
        {
          headers: {
            contentType: "application/json",
          },
          responseType: "text",
        }
      )
      .then((res) => {
        return res.data;
      });
  }

  async getQuestionVotedStatus(questionId: string) {
    return this.axios
      .get(`${this.secureVotingEndpoint}/questions/${questionId}/votes`)
      .then((res) => res.data);
  }

  async pingPresence(broadcastId: string): Promise<void> {
    return this.axios
      .post<void>(`presence/broadcast/${broadcastId}`)
      .then((res) => res.data);
  }

  async availableVotes(questionId: string): Promise<AvailableVotesResponse> {
    return this.axios
      .get<AvailableVotesResponse>(
        `${this.secureVotingEndpoint}/available-votes`,
        { params: { questionId } }
      )
      .then((res) => res.data);
  }
}

let apiService: ApiService;

// Spect8Chat relies on the axiosInstance returned by this function, so we are still returning the axiosInstance.
// TODO: Maybe updated chat-ui-vue to support / use class version?
function initApiService(store: Store<unknown>): AxiosInstance {
  if (apiService) {
    return apiService.axios;
  }

  if (!API_BASE_URL) {
    throw new Error("Missing env var - BASE_URL_CHAT");
  }

  const axiosInstance = getAxiosInstance(
    {
      baseURL: API_BASE_URL,
      responseType: "json",
    },
    store
  );

  apiService = new ApiService(axiosInstance);
  return axiosInstance;
}

export { apiService, initApiService };
