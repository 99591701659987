const options = {
  customProperties: true,
};

const dark = true;

const themes = {
  dark: {
    primary: "#FFFFFF",
    background: "#20232C",
    brand: "#00B5E5",
    secondary: "#16181E",
    tertiary: "#272727",
    accent: "#8C9EFF",
    info: "#2196F3",
    error: "#DB1800",
    success: "#35AC19",
    warning: "#FFD500",
  },
  light: {
    primary: "#646362",
    secondary: "#E3E4E4",
    background: "#FFFFFF",
    accent: "#A1C861",
    error: "#FF4E45",
    info: "#1D9BB2",
    success: "#4CAF50",
    warning: "#FFE05F",
    brand: "#08954C",
  },
};

export { options, dark, themes };
