



























import { PollAnswer, PollVote } from "@/panel/polls/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class PollAnswers extends Vue {
  @Prop({ required: true }) answers!: PollAnswer[];
  @Prop({ required: true }) votes!: PollVote[];
  @Prop({ required: true }) disabled!: boolean;
  @Prop({ required: true }) votesPerPerson!: number;

  selectedAnswers: PollAnswer[] = [];

  @Watch("votes", { immediate: true })
  onVotesChanged(): void {
    this.selectedAnswers = this.answers.filter(
      (answer: PollAnswer) =>
        this.votes.findIndex(
          (vote: PollVote) => vote.pollAnswer.answerId === answer.answerId
        ) !== -1
    );
  }

  vote(pollAnswer: PollAnswer): void {
    this.$emit("vote", pollAnswer);
  }

  get votesRemaining(): number {
    return this.votesPerPerson - this.votes.length;
  }
}
