import { Mutation } from "vuex";
import {
  BroadcastResource,
  Resource,
  ResourceMutation,
  ResourceState,
  TenantResource,
} from "../types";

export const mutations: Record<ResourceMutation, Mutation<ResourceState>> = {
  [ResourceMutation.AddResource](state, resource: Resource) {
    if (!resource.broadcastIds) {
      state.tenantResources.push(resource as TenantResource);
    } else {
      state.broadcastResources.push(resource as BroadcastResource);
    }
  },
  [ResourceMutation.RemoveResource](state, resourceId: string) {
    state.broadcastResources = state.broadcastResources.filter(
      (resource) => resource.id !== resourceId
    );
    state.tenantResources = state.tenantResources.filter(
      (resource) => resource.id !== resourceId
    );
  },
  [ResourceMutation.SetResources](state, resources: Resource[]) {
    const tenantResources: TenantResource[] = [];
    const broadcastResources: BroadcastResource[] = [];

    for (const resource of resources) {
      if (!resource.broadcastIds) {
        tenantResources.push(resource as TenantResource);
      } else {
        broadcastResources.push(resource as BroadcastResource);
      }
    }

    state.broadcastResources = broadcastResources;
    state.tenantResources = tenantResources;
  },
  [ResourceMutation.SetLoaded](state, loaded: boolean) {
    state.loaded = loaded;
  },
  [ResourceMutation.SetLoading](state, loading: boolean) {
    state.loading = loading;
  },
};
