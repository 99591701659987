


































































import { Vue, Component, Prop } from "vue-property-decorator";
import { SVQuestion, SVGetter } from "./types";
import { Getter } from "vuex-class";
import { SV_NAMESPACE } from "./store";

@Component({})
export default class SVSelect extends Vue {
  @Prop({ required: true }) question!: SVQuestion;
  @Prop({ default: false }) validating!: boolean;

  @Getter(`${SV_NAMESPACE}/${SVGetter.AvailableVotes}`)
  availableVotes!: number;

  selectedAnswers: string[] = [];

  get maximumVotesForUser(): number {
    return this.question.maximumVotes * this.availableVotes;
  }

  get isRepresenting(): boolean {
    return this.availableVotes > 1;
  }

  addVote(answerId: string): void {
    if (
      this.selectedAnswers.length >= this.maximumVotesForUser ||
      this.selectedAnswers.filter((id) => id === answerId).length >=
        this.availableVotes
    )
      return;

    this.selectedAnswers.push(answerId);
    this.emitVotesChanged();
  }

  subtractVote(answerId: string): void {
    const voteIndex = this.selectedAnswers.indexOf(answerId);
    if (voteIndex > -1) {
      this.selectedAnswers.splice(voteIndex, 1);
    }
    this.emitVotesChanged();
  }

  applyVote(answerId: string): void {
    const voteIndex = this.selectedAnswers.indexOf(answerId);

    if (voteIndex === -1) {
      const multiVote = this.maximumVotesForUser > 1;
      // If multi-vote and votes will exceed maximum, do nothing.
      if (multiVote && this.selectedAnswers.length >= this.maximumVotesForUser)
        return;

      this.selectedAnswers = multiVote
        ? [...this.selectedAnswers, answerId]
        : [answerId];
    } else {
      this.selectedAnswers.splice(voteIndex, 1);
    }

    this.emitVotesChanged();
  }

  emitVotesChanged(): void {
    this.$emit("change", this.selectedAnswers);
  }

  showIcon(answerId: string): boolean {
    return this.validating && this.selectedAnswers.indexOf(answerId) !== -1;
  }
}
