import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { NavigationState } from "../types";
import { PanelRootState } from "@/panel/store/PanelRootState";

export const NAVIGATION_NAMESPACE = "navigation";

const namespaced = true;

export const state: NavigationState = {
  navbarItems: [],
};

export const navigation: Module<NavigationState, PanelRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
