import { OverlayRootState } from "@/overlay/store/OverlayRootState";
import Vue from "vue";
import { ActionTree } from "vuex";
import { Notification, NotificationState } from "../types";

export const actions: ActionTree<NotificationState, OverlayRootState> = {
  // TODO: implement notification queue, only 1 notification is visible at the same time
  closeNotification(context): void {
    Vue.$log.debug("Close Notification");
    context.commit("setNotification", undefined);
  },
  showNotification(context, notification: Notification): void {
    Vue.$log.debug("Notification received", notification);
    context.commit("setNotification", notification);
  },
};
