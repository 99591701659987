import { GeneralSettings } from "./../types";
import { AppRootState } from "@/shared/@types/app";
import { GetterTree } from "vuex";
import { AppConfig, Config, ConfigState, Options } from "../types";

export const getters: GetterTree<ConfigState, AppRootState> = {
  initialized(state): boolean {
    return state.initialized;
  },
  appConfig(state): AppConfig | undefined {
    return state.appConfig;
  },
  config(state): Config | undefined {
    if (!state.tenantConfig || !state.broadcastConfig) {
      return undefined;
    }
    return { ...state.tenantConfig, ...state.broadcastConfig };
  },
  options(state): Options | undefined {
    return state.options;
  },
  agenda(state): string {
    return state.agenda;
  },
  tenantName(state): string {
    return state.tenantName;
  },
  settings(state): GeneralSettings | undefined {
    return state.settings;
  },
};
