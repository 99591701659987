




















































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Product from "./Product.vue";
import { MERCH_NAMESPACE } from "../store";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { TRACKING_NAMESPACE } from "@/shared/tracking/store";
import { ShopCategory, ShopDisclaimer, ShopItem } from "../types";
import { AppConfig, Config } from "@/shared/config/types";

@Component({
  components: { Product },
})
export default class MerchPage extends Vue {
  // @Action("fetchMerch", { namespace: MERCH_NAMESPACE }) fetchMerch!: Function;

  @Action("getCategories", { namespace: MERCH_NAMESPACE })
  getCategories!: () => Promise<void>;

  @Action("getShopItems", { namespace: MERCH_NAMESPACE })
  getShopItems!: (categoryId: string) => Promise<void>;

  @Action("getDisclaimers", { namespace: MERCH_NAMESPACE })
  getDisclaimers!: () => Promise<void>;

  @Action("logEvent", { namespace: TRACKING_NAMESPACE })
  logEvent!: Function;

  @Getter("appConfig", { namespace: CONFIG_NAMESPACE })
  appConfig!: AppConfig;

  @Getter("config", { namespace: CONFIG_NAMESPACE })
  config!: Config;

  // @Getter("merch", { namespace: MERCH_NAMESPACE })
  // merch!: Array<Merch>;

  @Getter("getShopCategories", { namespace: MERCH_NAMESPACE })
  shopCategories!: ShopCategory[];

  @Getter("getShopItems", { namespace: MERCH_NAMESPACE })
  shopItems!: ShopItem[];

  @Getter("getDisclaimers", { namespace: MERCH_NAMESPACE })
  disclaimers!: ShopDisclaimer[];

  clickedCategory = Object() as ShopCategory;
  loading = true;
  hasCategory = true;

  filterCategory(category: ShopCategory) {
    this.clickedCategory = category;
    this.getShopItems(category.id);
  }

  disclaimerText() {
    if (this.disclaimers[0].link) {
      const regex = /(https?:\/\/[^\s]+)/g;
      let currentDisclaimer = this.disclaimers[0].disclaimer;
      const linkList = currentDisclaimer.match(regex);
      linkList?.forEach((link) => {
        currentDisclaimer = currentDisclaimer.replace(
          regex,
          `<a href="#" onclick="window.open('${link}');">${link}</a>`
        );
      });

      return `<span>${currentDisclaimer}</span>`;
    } else return this.disclaimers[0].disclaimer;
  }

  mounted(): void {
    // this.fetchMerch(this.appConfig.tenant);
    this.getCategories().then(() => {
      if (this.shopCategories.length !== 0)
        this.getShopItems(this.shopCategories[0].id).then(() => {
          this.getDisclaimers().then(() => {
            this.clickedCategory = this.shopCategories[0];
            this.loading = false;
            this.hasCategory = true;
          });
        });
      else {
        this.loading = false;
        this.hasCategory = false;
      }
    });
  }
}
