









































import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo } from "@/shared/user/types";
import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { QA_NAMESPACE } from "../store";
import {
  QAAction,
  QAGetter,
  Question,
  QuestionDto,
  QuestionType,
} from "../types";

@Component({})
export default class VideoCallButton extends Vue {
  @Action(QAAction.CreateQuestion, { namespace: QA_NAMESPACE })
  saveQuestion!: (payload: QuestionDto) => Promise<void>;

  @Getter("userInfo", { namespace: USER_NAMESPACE })
  userInfo!: UserInfo;

  @Getter(QAGetter.Questions, { namespace: QA_NAMESPACE })
  questions!: Question[];

  savingVideoCall = false;

  confirmToggleView = false;

  async requestVideoCall() {
    if (this.savingVideoCall || this.disabled) return;

    if (!this.confirmToggleView) {
      this.confirmToggleView = true;
      return;
    }

    this.savingVideoCall = true;

    await this.saveQuestion({
      content: "Video Call",
      note: "",
      type: QuestionType.VIDEO,
    });

    setTimeout(() => {
      this.confirmToggleView = false;
      this.savingVideoCall = false;
    }, 350);
  }

  get disabled() {
    return (
      this.questions.filter((question) => {
        return (
          question.user.userId === this.userInfo.userId &&
          question.type === QuestionType.VIDEO
        );
      }).length > 0
    );
  }
}
