import { Poll, PollVote } from "@/panel/polls/types";
import Vue from "vue";
import { MutationTree } from "vuex";
import { PollState } from "../types";

// function sortPolls(polls: Poll[]): Poll[] {
//   return polls.sort((a, b) => {
//     const startDateA = new Date(a.startDate).getTime();
//     const startDateB = new Date(b.startDate).getTime();

//     return startDateA === startDateB ? 0 : startDateA < startDateB ? 1 : -1;
//   });
// }

function sortPolls(polls: Poll[]): Poll[] {
  const now = Date.now();

  return polls.sort((a, b) => {
    const timeRemainingA = new Date(a.endDate).getTime() - now;
    const timeRemainingB = new Date(b.endDate).getTime() - now;

    return timeRemainingA === timeRemainingB
      ? 0
      : timeRemainingA < timeRemainingB
      ? 1
      : -1;
  });
}

export const mutations: MutationTree<PollState> = {
  setLoading(state, isLoading: boolean) {
    Vue.set(state, "isLoading", isLoading);
  },
  setPolls(state, polls: Poll[]) {
    polls.map((poll) => (poll.moderatorShowResults = false));
    Vue.set(state, "polls", sortPolls(polls));
  },
  startPoll(state, poll: Poll) {
    let polls = [...state.polls];

    poll.moderatorShowResults = false;

    if (!polls.some((p) => p.id === poll.id)) {
      polls.push(poll);
    } else {
      polls = polls.map((p) => (p.id === poll.id ? poll : p));
    }

    Vue.set(state, "polls", sortPolls(polls));
  },
  deletePoll(state, deletedPoll: Poll) {
    Vue.set(
      state,
      "polls",
      state.polls.filter((poll) => poll.id !== deletedPoll.id)
    );
  },
  updatePoll(state, updatedPoll: Poll) {
    const existingPollIndex = state.polls.findIndex(
      (poll) => poll.id === updatedPoll.id
    );

    if (existingPollIndex > -1) {
      updatedPoll.moderatorShowResults =
        state.polls[existingPollIndex].moderatorShowResults;
      Vue.set(state.polls, existingPollIndex, updatedPoll);
    } else {
      updatedPoll.moderatorShowResults = false;
      state.polls = sortPolls([...state.polls, updatedPoll]);
    }
  },
  setUserVotes(
    state,
    { pollId, userVotes }: { pollId: string; userVotes: PollVote[] }
  ) {
    Vue.set(state.pollVotes, pollId, userVotes);
  },
  togglePollResults(state, pollId: string) {
    state.polls = state.polls.map((poll) => {
      if (poll.id === pollId) {
        poll.moderatorShowResults = !poll.moderatorShowResults;
      }
      return poll;
    });
  },
};
