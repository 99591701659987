import { UserInfo } from "@/shared/user/types";

export interface QAState {
  loading: boolean;
  questions: Question[];
}

export enum QAAction {
  FetchQuestions = "FetchQuestions",
  CreateQuestion = "CreateQuestion",
  AddQuestion = "AddQuestion",
  UpdateQuestion = "UpdateQuestion",
  DeleteQuestion = "DeleteQuestion",
  // Nchan Actions
  QuestionAsked = "QuestionAsked",
  QuestionEdited = "QuestionEdited",
  QuestionDeleted = "QuestionDeleted",
}

// Map Nchan events to a dedicated store action
export const QANchanEvents: Record<string, QAAction> = {
  QUESTION_ASKED: QAAction.QuestionAsked,
  QUESTION_EDITED: QAAction.QuestionEdited,
  QUESTION_DELETED: QAAction.QuestionDeleted,
};

export enum QAMutation {
  SetQuestions = "SetQuestions",
  AddQuestion = "AddQuestion",
  UpdateQuestion = "UpdateQuestion",
  RemoveQuestion = "RemoveQuestion",
  SetLoading = "SetLoading",
}

export enum QAGetter {
  Questions = "Questions",
}

export enum QuestionStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DISMISSED = "DISMISSED",
  ANSWERING = "ANSWERING",
  ANSWERED = "ANSWERED",
}

export enum QuestionType {
  TEXT = "TEXT",
  VIDEO = "VIDEO",
  IN_PERSON = "IN_PERSON",
}

export interface Question {
  id: string;
  broadcastId: string;
  content: string;
  type: QuestionType;
  status: QuestionStatus;
  answer: string;
  user: UserInfo;
}

export interface QuestionDto {
  content: string;
  note: string;
  type: QuestionType;
  category?: string;
}

export interface QuestionPatchDto {
  note: string;
  answer: string;
  status: string;
}

// interface QuestionUser {
//   userId: string;
//   tenantId: string;
//   externalUserId: string;
//   displayName: string;
//   roles: Role[];
// }

export interface QASnackBarError {
  message: string;
  color: string;
}

export interface CreateQuestionActionPayload {
  broadcastId: string;
  question: QuestionDto;
}
