






























































import { RegistrationResponse } from "@/panel/gema/types";
import { apiService } from "@/shared/services/api-service";
import { hexToRGB, isDark } from "@/shared/utils";
import { Vue, Component } from "vue-property-decorator";
import { Action } from "vuex-class";
import { QA_NAMESPACE } from "../../store";

import { QAAction, QASnackBarError } from "../../types";
import GemaQuestionInput from "./GemaQuestionInput.vue";

@Component({
  components: {
    GemaQuestionInput,
  },
})
export default class GemaQAPanel extends Vue {
  @Action(QAAction.FetchQuestions, { namespace: QA_NAMESPACE })
  fetchQuestions!: () => Promise<void>;

  showQuestionInput = false;
  questionCategory = "";

  snackbar = false;
  snackbarMessage = "";
  snackbarColor = "error";

  loading = true;

  registration: RegistrationResponse | null = null;

  async created() {
    const [registration] = await Promise.all([
      apiService.getMemberRegistration().catch(() => {
        //
      }),
      this.fetchQuestions(),
    ]);

    this.registration = registration || null;
    this.loading = false;
  }

  dismissSnackbar() {
    this.snackbar = false;
    this.snackbarMessage = "";
    this.snackbarColor = "error";
  }

  setSnackbarError($event: QASnackBarError) {
    this.snackbarMessage = $event.message;
    this.snackbarColor = $event.color || "error";
    this.snackbar = true;
  }

  goToQuestionInput(category: string) {
    this.questionCategory = category;
    this.showQuestionInput = true;
  }

  goToQuestionPanel() {
    this.questionCategory = "";
    this.showQuestionInput = false;
  }

  get brandTextColorLight(): boolean {
    const col = this.$vuetify.theme.currentTheme.brand?.toString();
    return !col || isDark(hexToRGB(col));
  }
}
