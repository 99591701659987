import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { IFrameBridgeState } from "../types";
import { AppRootState } from "@/shared/@types/app";

export const state: IFrameBridgeState = {};

export const IFRAME_BRIDGE_NAMESPACE = "iFrameBridge";

const namespaced = true;

export const iFrameBridge: Module<IFrameBridgeState, AppRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
