import { Media } from "../media/types";

export interface UserState {
  created: boolean;
  user?: User;
  userInfo?: UserInfo;

  // error states
  hasUpdateError: boolean;
}

export interface User {
  id: string;
  editRequest: EditRequest | null;
  email: string | null;
  username: string;

  lastModified: number;
  timeCreated: number;

  roles: Role[];

  tenant: Tenant | null;
  tenantId: string;
}

export interface EditRequest {
  color: string | null;
  displayName?: string | null;
  avatar: Media | null;
  badge: Media | null;
}

export interface Registration {
  applicationId: string;
  roles: Role[];
}

export interface Tenant {
  tenantId: string;
  name: string;
}

export interface UserInfo {
  avatar: Media;
  color: string | null;
  displayName: string | null;
  firstName: string | null;
  userId: string;
  lastName: string | null;
  roles: Role[];
  username: string;
  badge: Media | null;
}

export interface UserUpdatePayload {
  color: string | null;
  displayName: string | null;
  avatarFile?: File;
  avatarUrl?: string;
  badgeUrl?: string;
}

export enum Role {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_MODERATOR = "ROLE_MODERATOR",
  ROLE_USER = "ROLE_USER",
}
