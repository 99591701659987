import { PanelRootState } from "@/panel/store/PanelRootState";
import { IFRAME_BRIDGE_NAMESPACE } from "@/shared/iFrameBridge/store";
import { TRACKING_NAMESPACE } from "@/shared/tracking/store";
import { ActionTree } from "vuex";
import { AppState } from "../types";

export const actions: ActionTree<AppState, PanelRootState> = {
  toggleVisibility(context, visibility?: boolean) {
    const visible = visibility ? visibility : !context.state.visible;
    if (visible !== context.state.visible) {
      context.commit("visible", visible);

      context.dispatch(
        TRACKING_NAMESPACE + "/logEvent",
        {
          ea: "toggle",
          ec: "panel",
          ed: visible ? "open" : "closed",
        },
        { root: true }
      );

      context.dispatch(
        IFRAME_BRIDGE_NAMESPACE + "/postMessage",
        {
          type: "appEvent",
          payload: {
            category: "app",
            action: visible ? "visible" : "invisible",
          },
        },
        { root: true }
      );
    }
  },
};
