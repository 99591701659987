import { API_BASE_URL } from "@/helpers/envConsts";
import { CreatePollDto, Poll, PollVote } from "@/panel/polls/types";
import { getAxiosInstance } from "@/shared/services/axios-base";
import { AxiosInstance } from "axios";
import { Store } from "vuex";

class PollsService {
  pollsEndpoint = "/polls";
  pollVotesEndpoint = "/pollvotes";

  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  public async getPollById(pollId: string): Promise<Poll> {
    return this.axios
      .get<Poll>(`${this.pollsEndpoint}/${pollId}`)
      .then((res) => res.data);
  }

  public async getPollsByBroadcastIds(
    tenantId: string,
    broadcastIds: string[]
  ): Promise<Poll[]> {
    const ids = broadcastIds.join(",");

    return this.axios
      .get<Poll[]>(
        `${this.pollsEndpoint}/broadcasts?tenantId=${tenantId}&broadcastIds=${ids}`
      )
      .then((res) => res.data);
  }

  public async getPollsByChannelIds(
    tenantId: string,
    channelIds: string[]
  ): Promise<Poll[]> {
    const ids = channelIds.join(",");

    return this.axios
      .get<Poll[]>(
        `${this.pollsEndpoint}/broadcasts?tenantId=${tenantId}&channelIds=${ids}`
      )
      .then((res) => res.data);
  }

  public async getPollVotesByPollIdAndUserId(
    pollId: string,
    userId: string
  ): Promise<PollVote[]> {
    return this.axios
      .get<PollVote[]>(`${this.pollVotesEndpoint}/${pollId}/${userId}`)
      .then((res) => res.data);
  }

  public async saveVote({
    userId,
    pollId,
    pollAnswer,
  }: PollVote): Promise<PollVote[]> {
    return this.axios
      .post(`${this.pollVotesEndpoint}/${pollId}`, {
        userId,
        pollAnswer,
      })
      .then((res) => res.data);
  }

  public async createPoll(poll: CreatePollDto): Promise<void> {
    return this.axios
      .post(`${this.pollsEndpoint}`, poll)
      .then((res) => res.data);
  }

  public async publishPollResults(pollId: string): Promise<void> {
    return this.axios.post(`${this.pollsEndpoint}/${pollId}/results/publish`);
  }

  public async triggerPollResults(pollId: string): Promise<void> {
    return this.axios.post(`${this.pollsEndpoint}/${pollId}/results`);
  }
}

let pollsService: PollsService;

function initPollsService(store: Store<unknown>): PollsService {
  if (pollsService) {
    return pollsService;
  }

  if (!API_BASE_URL) {
    throw new Error("Missing env var - BASE_URL_POLLS");
  }

  const axiosInstance = getAxiosInstance(
    {
      baseURL: API_BASE_URL,
      responseType: "json",
      withCredentials: true,
    },
    store
  );

  pollsService = new PollsService(axiosInstance);
  return pollsService;
}

export { initPollsService, pollsService };
