
































import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo } from "@/shared/user/types";
import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { QA_NAMESPACE } from "../store";
import {
  QAAction,
  QAGetter,
  QASnackBarError,
  Question,
  QuestionStatus,
} from "../types";
import QuestionList from "./QuestionList.vue";
import QuestionInput from "./QuestionInput.vue";

@Component({
  components: {
    QuestionList,
    QuestionInput,
  },
})
export default class QAPanel extends Vue {
  @Getter(QAGetter.Questions, { namespace: QA_NAMESPACE })
  questions!: Question[];

  @Getter("userInfo", { namespace: USER_NAMESPACE })
  userInfo!: UserInfo;

  @Action(QAAction.FetchQuestions, { namespace: QA_NAMESPACE })
  fetchQuestions!: () => Promise<void>;

  @Action(QAAction.AddQuestion, { namespace: QA_NAMESPACE })
  addQuestion!: (question: Question) => Promise<void>;

  snackbar = false;
  snackbarMessage = "";
  snackbarColor = "error";

  created() {
    this.fetchQuestions();
  }

  dismissSnackbar() {
    this.snackbar = false;
    this.snackbarMessage = "";
    this.snackbarColor = "error";
  }

  setSnackbarError($event: QASnackBarError) {
    this.snackbarMessage = $event.message;
    this.snackbarColor = $event.color || "error";
    this.snackbar = true;
  }

  get myQuestions() {
    return this.questions.filter(
      (question) => question.user.userId === this.userInfo.userId
    );
  }

  get approvedQuestions() {
    return this.questions.filter(
      (question) => question.status === QuestionStatus.APPROVED
    );
  }
}
