import { PanelRootState } from "@/panel/store/PanelRootState";
import { GetterTree } from "vuex";
import { MerchState, ShopCategory, ShopDisclaimer, ShopItem } from "../types";

export const getters: GetterTree<MerchState, PanelRootState> = {
  // merch(state): Array<Merch> {
  //   return state.merch;
  // },
  getShopCategories(state): ShopCategory[] {
    return state.shopCategories;
  },
  getShopItems(state): ShopItem[] {
    return state.shopItems;
  },
  getDisclaimers(state): ShopDisclaimer[] {
    return state.disclaimers;
  },
};
