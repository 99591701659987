


















































import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo } from "@/shared/user/types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { QA_NAMESPACE } from "../store";
import { QAAction, QASnackBarError, Question, QuestionStatus } from "../types";

@Component
export default class QuestionList extends Vue {
  @Prop({ default: true }) isExpandedInit!: boolean;
  @Prop({ default: false }) showUserActions!: boolean;

  @Prop({ required: true }) header!: string;
  @Prop({ required: true }) emptyText!: string;
  @Prop({ required: true }) questions!: Question[];

  disabled: string[] = [];

  @Getter("userInfo", { namespace: USER_NAMESPACE })
  userInfo!: UserInfo;

  @Action(QAAction.DeleteQuestion, { namespace: QA_NAMESPACE })
  removeQuestion!: (questionId: string) => Promise<void>;

  isExpanded = this.isExpandedInit ? true : false;

  async deleteQuestion(questionId: string) {
    try {
      this.disabled.push(questionId);
      await this.removeQuestion(questionId);
      this.disabled = this.disabled.filter(
        (disabledId) => disabledId !== questionId
      );
    } catch (error) {
      this.emitSnackbarError({
        message: this.$t("qa.deleteError").toString(),
        color: "error",
      });
    }
  }

  questionStatusLabelColor(status: QuestionStatus) {
    const classes = ["mr-2", "text--darken-2"];

    switch (status) {
      case QuestionStatus.PENDING:
        classes.push("warning--text");
        break;
      case QuestionStatus.DISMISSED:
        classes.push("error--text");
        break;
      case QuestionStatus.APPROVED:
      case QuestionStatus.ANSWERED:
      case QuestionStatus.ANSWERING:
        classes.push("success--text");
        break;
    }

    return classes.join(" ");
  }

  questionStatusLabel(status: QuestionStatus): string {
    switch (status) {
      case QuestionStatus.PENDING:
        return this.$t("qa.statusPending").toString();
      case QuestionStatus.DISMISSED:
        return this.$t("qa.statusDismissed").toString();
      case QuestionStatus.APPROVED:
        return this.$t("qa.statusApproved").toString();
      case QuestionStatus.ANSWERED:
        return this.$t("qa.statusAnswered").toString();
      case QuestionStatus.ANSWERING:
        return this.$t("qa.statusAnswering").toString();
    }
  }

  emitSnackbarError(payload: QASnackBarError) {
    this.$emit("error", payload);
  }
}
