type RgbColor = { r: number; g: number; b: number };
export const hexToRGB = (h: string): RgbColor => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length == 4) {
    r = parseInt(h[1] + h[1], 16);
    g = parseInt(h[2] + h[2], 16);
    b = parseInt(h[3] + h[3], 16);

    // 6 digits
  } else if (h.length == 7) {
    r = parseInt(h[1] + h[2], 16);
    g = parseInt(h[3] + h[4], 16);
    b = parseInt(h[5] + h[6], 16);
  }

  return { r, g, b };
};

export const isDark = ({ r, g, b }: RgbColor): boolean => {
  const y = r * 0.299 + g * 0.587 + b * 0.114;
  return y / 255 < 0.5;
};
