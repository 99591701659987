export interface ResourceState {
  loading: boolean;
  loaded: boolean;
  tenantResources: TenantResource[];
  broadcastResources: BroadcastResource[];
}

export enum ResourceAction {
  FetchResources = "FetchResources",
  AddResource = "AddResource",
  DeleteResource = "DeleteResource",
}

// Map Nchan events to a dedicated store action
export const ResourceNchanEvents: Record<string, ResourceAction> = {
  RESOURCE_ADDED: ResourceAction.AddResource,
  RESOURCE_DELETED: ResourceAction.DeleteResource,
};

export enum ResourceMutation {
  SetResources = "SetResources",
  AddResource = "AddResource",
  RemoveResource = "RemoveResource",
  SetLoading = "SetLoading",
  SetLoaded = "SetLoaded",
}

export enum ResourceGetter {
  BroadcastResources = "BroadcastResources",
  TenantResources = "TenantResources",
  ResourceCount = "ResourceCount",
  AllResources = "AllResources",
}

export interface Resource {
  id: string;
  tenantId: string;
  title: string;
  upManMedia: UpManMedia;
  timeCreated: {
    nane: string;
    epochSecond: string;
  };
  broadcastIds: string[] | null;
}

export interface BroadcastResource extends Resource {
  broadcastIds: string[];
}
export interface TenantResource extends Resource {
  broadcastIds: null;
}

export interface UpManMedia {
  upmanId: string;
  uploaderId: string;
  fileSize: number;
  tags: string[];
  filename: string;
  publicPath: string;
  fileExtension: string;
  mimeType: string;
}
