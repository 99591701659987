import { AppRootState } from "@/shared/@types/app";
import { apiService } from "@/shared/services/api-service";
import Vue from "vue";
import { ActionTree } from "vuex";
import { ThemeConfig, ThemeState } from "../types";

export const actions: ActionTree<ThemeState, AppRootState> = {
  async fetchTheme(context, tenantId: string): Promise<ThemeConfig | null> {
    try {
      const theme = await apiService.getThemeConfig(tenantId);
      context.commit("theme", theme);
      Vue.$log.debug("Set Theme success", theme);
      return theme;
    } catch (error) {
      Vue.$log.debug("Set Theme error", error);
      context.dispatch(
        "tracking/logError",
        {
          ea: "error",
          ec: "themeConfig",
          ed: error,
        },
        { root: true }
      );
    }
    return null;
  },
};
