import axios, { AxiosInstance } from "axios";

let authService: AxiosInstance;
function initAuthService(): AxiosInstance {
  if (authService) {
    return authService;
  }

  authService = axios.create({
    baseURL: process.env.VUE_APP_SESSION_AUTH_URL,
    responseType: "json",
  });
  return authService;
}

export { authService, initAuthService };
