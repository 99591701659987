

































































import { Component } from "vue-property-decorator";
import { PollAnswer } from "../../types";
import DefaultPoll from "./DefaultPoll.vue";
import PollQuestion from "./shared-components/PollQuestion.vue";
import PollUserInput from "./shared-components/PollUserInput.vue";

@Component({
  components: {
    PollQuestion,
    PollUserInput,
  },
})
export default class OpenPoll extends DefaultPoll {
  maxResultDisplayHeight = "250px";

  get predefinedAnswers(): PollAnswer[] {
    return this.poll.answers.filter((answer) => !answer.userSubmission);
  }

  get sortedAnswers(): PollAnswer[] {
    return this.poll.answers.sort((a, b) => b.voteCount - a.voteCount);
  }
}
