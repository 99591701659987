import { SVAction, SVNchanEvents } from "./../../../panel/secure-voting/types";
import { SV_NAMESPACE } from "./../../../panel/secure-voting/store/index";
import { NOTIFICATION_NAMESPACE } from "@/overlay/notifications/store";
import { Notification } from "@/overlay/notifications/types";
import { AdAction, AD_NAMESPACE } from "@/panel/ads/types";
import { APP_NAMESPACE } from "@/panel/app/store";
import { openAndNavigate } from "@/panel/navigation/NavigationUtils";
import { NAVIGATION_NAMESPACE } from "@/panel/navigation/store";
import { NavigationId, NavigationItem } from "@/panel/navigation/types";
import { POLL_NAMESPACE } from "@/panel/polls/store";
import { PollAction } from "@/panel/polls/types";
import { QA_NAMESPACE } from "@/panel/qa/store";
import { QANchanEvents } from "@/panel/qa/types";
import { RESOURCE_NAMESPACE } from "@/panel/resources/store";
import { ResourceNchanEvents } from "@/panel/resources/types";
import { AppRootState } from "@/shared/@types/app";
import { AUTH_NAMESPACE } from "@/shared/auth/store";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { AppConfig } from "@/shared/config/types";
import { NCHAN_NAMESPACE } from "@/shared/nchan/store";
import { THEME_NAMESPACE } from "@/shared/theme/store";
import { ThemeConfig } from "@/shared/theme/types";
import { generateRandomId } from "@/shared/utils/RandomId";
import Vue from "vue";
import { Route } from "vue-router";
import { ActionContext, ActionTree } from "vuex";
import {
  IFrameAckMessage,
  IFrameBridgeState,
  IFrameErrorPayload,
  IFrameEventMessage,
  IFrameEventPayload,
  IFrameInitMessage,
  IFrameMessage,
} from "../types";
import { isGema } from "@/shared/utils";

// const ALLOWED_ORIGIN_LIST = process.env.VUE_APP_ALLOWED_ORIGIN_LIST
//   ? JSON.parse(process.env.VUE_APP_ALLOWED_ORIGIN_LIST)
//   : [];

const APP_EVENT_RESEND_TIMEOUT = 5000;
let appEventMessage: IFrameEventMessage | null = null;
let appEventMessageCheckTimeoutId: number | null = null;

function checkAppEventMessage() {
  if (appEventMessage !== null) {
    // resend message
    window.parent.postMessage(JSON.stringify(appEventMessage), "*");
    Vue.$log.debug("Resend Message", appEventMessage);
    // check again after timeout
    appEventMessageCheckTimeoutId = setTimeout(
      checkAppEventMessage,
      APP_EVENT_RESEND_TIMEOUT
    );
  }
}

function stopAppEventMessageCheck() {
  if (appEventMessageCheckTimeoutId !== null) {
    clearTimeout(appEventMessageCheckTimeoutId);
    appEventMessageCheckTimeoutId = null;
  }
}

function startAppEventMessageCheck(message: IFrameEventMessage) {
  stopAppEventMessageCheck();
  // store message for acknowledgement
  appEventMessage = message;
  appEventMessageCheckTimeoutId = setTimeout(
    checkAppEventMessage,
    APP_EVENT_RESEND_TIMEOUT
  );
}

function ackAppEventMessage(messageId: string | undefined) {
  if (appEventMessage !== null && appEventMessage.id === messageId) {
    Vue.$log.debug("Acknowledge Message", messageId);
    stopAppEventMessageCheck();
    appEventMessage = null;
  }
}

async function handleIframeMessage(
  {
    dispatch,
    commit,
    rootGetters,
  }: ActionContext<IFrameBridgeState, AppRootState>,
  message: IFrameMessage
): Promise<void | IFrameErrorPayload> {
  if (message.type && message.payload) {
    // Ignore init message once the app config is set
    if (message.type === "init") {
      const currentAppConfig: AppConfig =
        rootGetters[`${CONFIG_NAMESPACE}/appConfig`];
      const currentTheme: ThemeConfig = rootGetters[`${THEME_NAMESPACE}/theme`];
      const currentAuth: boolean =
        rootGetters[`${AUTH_NAMESPACE}/authenticated`];
      // if initialized is true, the app had been started already before this call
      const initialized: boolean =
        rootGetters[`${CONFIG_NAMESPACE}/initialized`];
      const initPayload = (message as IFrameInitMessage).payload;

      if (!currentTheme) {
        dispatch(`${THEME_NAMESPACE}/fetchTheme`, initPayload.tenantId, {
          root: true,
        });
      }

      // todo:rMove
      if (initPayload.hash) {
        dispatch(`${SV_NAMESPACE}/${SVAction.SetHash}`, initPayload.hash, {
          root: true,
        });
      }

      // authenticate user
      if (!currentAuth) {
        const authenticated: boolean = await dispatch(
          `${AUTH_NAMESPACE}/authenticate`,
          {
            demo: initPayload.options?.demo ?? false,
            isFusionAuth: initPayload.options?.isFusionAuth ?? false,
            tenantId: initPayload.tenantId,
            tenantToken: initPayload.tenantToken,
          },
          { root: true }
        );

        if (!authenticated) {
          return {
            errorMessage: "Authentication failed",
            receivedMessage: message,
          } as IFrameErrorPayload;
        }

        if (currentAppConfig === undefined) {
          // set app Config
          await dispatch(
            `${CONFIG_NAMESPACE}/setAppConfig`,
            {
              platform: process.env.VUE_APP_PLATFORM,
              tenantId: initPayload.tenantId,
              broadcastId: "",
              broadcastName: initPayload.broadcastName,
              tenantToken: initPayload.tenantToken,
              nchanUrl: process.env.VUE_APP_NCHAN_URL,
            },
            { root: true }
          );
        }

        if (!initialized) {
          // set options
          if (initPayload.options) {
            dispatch(`${CONFIG_NAMESPACE}/setOptions`, initPayload.options, {
              root: true,
            });

            // set initial app visibility
            if (initPayload.options.initialVisibility !== undefined) {
              commit(
                `${APP_NAMESPACE}/visible`,
                initPayload.options.initialVisibility,
                { root: true }
              );
            }
          }

          // fetch navbar items
          await dispatch(
            `${NAVIGATION_NAMESPACE}/getNavbarItems`,
            rootGetters[`${CONFIG_NAMESPACE}/appConfig`].broadcastId,
            {
              root: true,
            }
          );

          // fetch tenant and broadcast config
          await dispatch(
            `${CONFIG_NAMESPACE}/fetchTenantConfig`,
            initPayload.tenantId,
            {
              root: true,
            }
          );

          // fetch registration (depends on fetchTenantConfig)
          if (isGema(rootGetters[`${CONFIG_NAMESPACE}/tenantName`]))
            await dispatch(`${NAVIGATION_NAMESPACE}/getRegistration`, null, {
              root: true,
            });

          await dispatch(`${CONFIG_NAMESPACE}/fetchBroadcastConfig`, null, {
            root: true,
          });
          await dispatch(`${CONFIG_NAMESPACE}/fetchSettings`, null, {
            root: true,
          });

          // TODO: Quick solution to prevent nchan connections if just the chat tab is active (sdtv)
          const navBarItems: NavigationItem[] =
            rootGetters[`${NAVIGATION_NAMESPACE}/navbarItems`];
          if (
            !rootGetters[`${NCHAN_NAMESPACE}/client`] &&
            !(
              navBarItems.length === 1 &&
              navBarItems.findIndex(
                (navItem: NavigationItem) => navItem.name === NavigationId.CHAT
              ) !== -1
            )
          ) {
            dispatch(
              `${NCHAN_NAMESPACE}/init`,
              rootGetters[`${CONFIG_NAMESPACE}/appConfig`],
              { root: true }
            );
          }
        }
      }
    } else if (message.type === "appEvent") {
      const eventMessage: IFrameEventPayload = (message as IFrameEventMessage)
        .payload;
      if (eventMessage.category === "theme" && eventMessage.data) {
        commit(`${THEME_NAMESPACE}/theme`, eventMessage.data as ThemeConfig, {
          root: true,
        });
      } else if (
        eventMessage.category === "notification" &&
        eventMessage.data
      ) {
        dispatch(
          `${NOTIFICATION_NAMESPACE}/showNotification`,
          eventMessage.data as Notification,
          { root: true }
        );
      } else if (eventMessage.category === "navigation" && eventMessage.data) {
        openAndNavigate(
          () =>
            dispatch(`${APP_NAMESPACE}/toggleVisibility`, true, {
              root: true,
            }),
          eventMessage.data as Route
        );
      } else if (eventMessage.category === "visibility" && eventMessage.data) {
        dispatch(
          `${APP_NAMESPACE}/toggleVisibility`,
          eventMessage.data as boolean,
          {
            root: true,
          }
        );
      } else if (
        Object.values(AdAction).includes(eventMessage.category as AdAction)
      ) {
        dispatch(
          `${AD_NAMESPACE}/${eventMessage.category}`,
          eventMessage.data,
          {
            root: true,
          }
        );
      } else if (
        Object.values(PollAction).includes(eventMessage.category as PollAction)
      ) {
        dispatch(
          `${POLL_NAMESPACE}/${eventMessage.category}`,
          eventMessage.data,
          {
            root: true,
          }
        );
      } else if (
        Object.keys(SVNchanEvents).includes(eventMessage.category as string) &&
        eventMessage.data
      ) {
        dispatch(
          `${SV_NAMESPACE}/${SVNchanEvents[eventMessage.category as string]}`,
          eventMessage.data,
          {
            root: true,
          }
        );
      } else if (
        Object.keys(ResourceNchanEvents).includes(
          eventMessage.category as string
        ) &&
        eventMessage.data
      ) {
        dispatch(
          `${RESOURCE_NAMESPACE}/${
            ResourceNchanEvents[eventMessage.category as string]
          }`,
          eventMessage.data,
          {
            root: true,
          }
        );
      } else if (
        Object.keys(QANchanEvents).includes(eventMessage.category as string)
      ) {
        dispatch(
          `${QA_NAMESPACE}/${QANchanEvents[eventMessage.category as string]}`,
          eventMessage.data,
          {
            root: true,
          }
        );
      } else if (eventMessage.category === "config" && eventMessage.data) {
        dispatch(`${CONFIG_NAMESPACE}/setConfig`, eventMessage.data, {
          root: true,
        });
      } else if (eventMessage.category === "options" && eventMessage.data) {
        // only allow options updates after init
        const initialized: boolean =
          rootGetters[`${CONFIG_NAMESPACE}/initialized`];
        if (initialized) {
          dispatch(`${CONFIG_NAMESPACE}/setOptions`, eventMessage.data, {
            root: true,
          });
        } else {
          throw Error("Not initialized");
        }
      } else if (eventMessage.category === "language" && eventMessage.data) {
        dispatch(`${CONFIG_NAMESPACE}/setOptions`, eventMessage.data, {
          root: true,
        });
      }
    } else if (message.type === "ack") {
      ackAppEventMessage((message as IFrameAckMessage).payload.messageId);
    }
  }
  return;
}

export const actions: ActionTree<IFrameBridgeState, AppRootState> = {
  async handleMessage(
    context,
    messageEvent: MessageEvent
  ): Promise<void | IFrameErrorPayload> {
    // if (
    //   ALLOWED_ORIGIN_LIST.includes(messageEvent.origin) &&
    //   messageEvent.data
    // ) {

    try {
      const message: IFrameMessage = JSON.parse(messageEvent.data);
      Vue.$log.debug("IFrameMessage", message);
      return handleIframeMessage(context, message);
    } catch (err) {
      return {
        errorMessage: (err as Error).message,
        receivedMessage: messageEvent.data,
      } as IFrameErrorPayload;
    }
  },
  postMessage(context, message: IFrameMessage): void {
    // set message source if not available
    if (!message.source) {
      message.source = context.rootState.applicationType;
    }
    if (message.type === "appEvent") {
      if (
        message.source &&
        message.destination &&
        message.source === message.destination
      ) {
        handleIframeMessage(context, message);
        return;
      }
      const messageId = generateRandomId();
      (message as IFrameEventMessage).id = messageId;
      (message as IFrameEventMessage).timestamp = Date.now();
      startAppEventMessageCheck(message as IFrameEventMessage);
    }
    window.parent.postMessage(JSON.stringify(message), "*");
    Vue.$log.debug("postMessage", message);
  },
};
