















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import { TRACKING_NAMESPACE } from "@/shared/tracking/store";
import { ShopItem } from "../types";

@Component({})
export default class Product extends Vue {
  @Action("logEvent", { namespace: TRACKING_NAMESPACE })
  logEvent!: Function;

  @Prop()
  product!: ShopItem;

  hovering = false;

  clickAction(event: Event, merch: ShopItem): void {
    event.stopImmediatePropagation();
    event.stopPropagation();

    if (!merch.redirectLink.match(/^https?:\/\//i)) {
      merch.redirectLink = "http://" + merch.redirectLink;
    }

    window.open(merch.redirectLink, "_blank");

    this.logEvent({ ea: "click", ec: "product", ed: merch.redirectLink });
  }

  getCurrency(cur: string) {
    if (cur === "€ (EUR)") return "€";
    else if (cur === "$ (USD)") return "$";
    else return "";
  }
}
