import { DEFAULT_LOCALE } from "@/plugins/i18n/vue-i18n-plugin";
import { AppRootState } from "@/shared/@types/app";
import { Module } from "vuex";
import { ConfigState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state: ConfigState = {
  initialized: false,
  agenda: "",
  options: {
    demo: false,
    isFusionAuth: false,
    initialVisibility: true,
    hiddenAppbar: false,
    profile: true,
    profilePictures: true,
    collapsible: true,
    defaultLanguage: DEFAULT_LOCALE,
    chatConfig: {},
  },
  tenantName: "",
};

export const CONFIG_NAMESPACE = "config";

const namespaced = true;

export const config: Module<ConfigState, AppRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
