import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { NotificationState } from "../types";
import { OverlayRootState } from "@/overlay/store/OverlayRootState";

export const NOTIFICATION_NAMESPACE = "notification";

const namespaced = true;

export const state: NotificationState = {};

export const notification: Module<NotificationState, OverlayRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
