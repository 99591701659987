
























import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { Config } from "@/shared/config/types";
import { Vue, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { getResourceFilename } from "../helpers";
import { RESOURCE_NAMESPACE } from "../store";
import { Resource, ResourceGetter } from "../types";
import ResourceInfo from "./ResourceInfo.vue";

@Component({
  components: {
    ResourceInfo,
  },
})
export default class ResourceList extends Vue {
  @Getter(ResourceGetter.AllResources, { namespace: RESOURCE_NAMESPACE })
  allResources!: Resource[];

  @Getter("config", { namespace: CONFIG_NAMESPACE })
  config!: Config;

  get resources(): Resource[] {
    return this.groupResources(this.allResources);
  }

  groupResources(resources: Resource[]): Resource[] {
    const groupedResources: Record<string, Resource[]> = {};

    // Group Resources by Mime Type
    resources.forEach((resource) => {
      const mimeType = resource.upManMedia.mimeType;
      const groupedItems = groupedResources[mimeType];

      if (!groupedItems) {
        groupedResources[mimeType] = [resource];
        return;
      }

      groupedItems.push(resource);
      groupedResources[mimeType] = groupedItems;
    });

    return Object.values(groupedResources).flat();
  }

  fileTypeIcon({ upManMedia: { mimeType } }: Resource): string {
    const [type, subtype] = mimeType.split("/");

    switch (type.toLowerCase()) {
      case "application":
        return this.fileSubTypeIcon(subtype);
      case "image":
        return "mdi-image";
      case "text":
        return "mdi-file-document";
      case "audio":
        return "mdi-file-music";
      case "video":
        return "mdi-file-video";
      default:
        return "mdi-file";
    }
  }

  fileSubTypeIcon(subType: string): string {
    if (!subType) return "mdi-file";

    switch (subType.toLowerCase()) {
      case "pdf":
        return "mdi-file-pdf-box";

      case "msword":
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "vnd.oasis.opendocument.text":
      case "rtf":
        return "mdi-file-document";

      case "vnd.openxmlformats-officedocument.presentationml.presentation":
      case "vnd.ms-powerpoint":
      case "vnd.oasis.opendocument.presentation":
        return "mdi-file-powerpoint";

      case "vnd.oasis.opendocument.spreadsheet":
      case "vnd.ms-excel":
      case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "xml":
        return "mdi-file-table";
      case "epub+zip":
      case "gzip":
      case "x-7z-compressed":
      case "x-tar":
      case "zip":
        return "mdi-folder-zip";
      default:
        return "mdi-file";
    }
  }

  downloadLink({ upManMedia: { publicPath } }: Resource): string {
    return this.config.mediaUploadBaseUrl + publicPath;
  }

  fileName(resource: Resource): string {
    return getResourceFilename(resource);
  }
}
