import "vuetify/dist/vuetify.min.css";
import Vue from "vue";
import Vuetify from "vuetify";
import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

import { i18n } from "@/plugins/i18n/vue-i18n-plugin";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: require("../themes/demo-theme.ts"),
  lang: {
    locales: { de, en },
    current: i18n.locale,
  },
});
