import { AppRootState } from "@/shared/@types/app";
import { GetterTree } from "vuex";
import { AuthState } from "../types";

export const getters: GetterTree<AuthState, AppRootState> = {
  authenticated(state): boolean {
    return state.authenticated;
  },
  authHeader(state): {} {
    return {
      headers: {
        Authorization: "Bearer " + state.accessToken,
      },
    };
  },
  accessToken(state): string | undefined {
    return state.accessToken;
  },
  refreshToken(state): string | undefined {
    return state.refreshToken;
  },
};
