import { Module } from "vuex";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AppState } from "../types";
import { PanelRootState } from "@/panel/store/PanelRootState";

export const APP_NAMESPACE = "app";

const namespaced = true;

export const state: AppState = {
  visible: true,
};

export const app: Module<AppState, PanelRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
