import { AppRootState } from "@/shared/@types/app";
import { Module } from "vuex";
import { ThemeState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state: ThemeState = {};

export const THEME_NAMESPACE = "theme";

const namespaced = true;

export const theme: Module<ThemeState, AppRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
