import { Getter } from "vuex";
import {
  Attendance,
  GemaGetter,
  GemaState,
  RegistrationResponse,
  RegistrationSettings,
  SVSettings,
} from "../types";
import { PanelRootState } from "@/panel/store/PanelRootState";

export const getters: Record<GemaGetter, Getter<GemaState, PanelRootState>> = {
  [GemaGetter.Member](state): RegistrationResponse | null {
    return state.member;
  },
  [GemaGetter.MemberAttendance](state): Attendance | null {
    return state.member?.registration?.attendanceType ?? null;
  },
  [GemaGetter.IsMT](state): boolean {
    return (
      state.member?.member.memberStatus === "A" &&
      (state.member.member.group === "K" ||
        state.member.member.group === "T" ||
        state.member.member.group === "MT") &&
      !state.member.member.delegate
    );
  },

  [GemaGetter.SVSettings](state): SVSettings | null {
    return state.svSettings;
  },
  [GemaGetter.RegistrationSettings](state): RegistrationSettings | null {
    return state.registrationSettings;
  },
  [GemaGetter.IsNMR](state): boolean {
    return state.member?.member.group === "SV" ?? false;
  },
  [GemaGetter.RepresentatedMembers](state): RegistrationResponse[] {
    return state.representedMembers;
  },
};
