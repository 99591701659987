import { RegistrationResponse } from "@/panel/gema/types";
import { MutationTree } from "vuex";
import { NavigationItem, NavigationState } from "../types";

export const mutations: MutationTree<NavigationState> = {
  setNavbarItems(state: NavigationState, value: NavigationItem[]) {
    state.navbarItems = value;
  },
  setRegistration(state, registration: RegistrationResponse) {
    state.registration = registration;
  },
};
