





























import { Vue, Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import SecureVotingQuestion from "./SecureVotingQuestion.vue";
import { SVAction, SVGetter, SVQuestion, SVVotePayload } from "./types";

import { SV_NAMESPACE } from "./store";
import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo } from "@/shared/user/types";
import {
  GemaAction,
  GemaGetter,
  RegistrationResponse,
  RegistrationStatus,
} from "../gema/types";
import { GEMA_NAMESPACE } from "../gema/store";

@Component({
  components: {
    SecureVotingQuestion,
  },
})
export default class SecureVoting extends Vue {
  @Action(`${SV_NAMESPACE}/${SVAction.PrepareCrypto}`)
  prepareCrypto!: () => Promise<void>;
  @Action(`${SV_NAMESPACE}/${SVAction.SetActiveQuestion}`)
  setActiveQuestion!: (question: SVQuestion | null) => void;
  @Action(`${SV_NAMESPACE}/${SVAction.GetActiveQuestion}`)
  getActiveQuestion!: () => Promise<SVQuestion[]>;
  @Action(`${SV_NAMESPACE}/${SVAction.SubmitVote}`)
  submitVote!: (votes: SVVotePayload[]) => Promise<void>;
  @Action(`${GEMA_NAMESPACE}/${GemaAction.FetchMe}`)
  fetchMe!: () => Promise<RegistrationResponse>;
  @Action(`${GEMA_NAMESPACE}/${GemaAction.FetchRepresentatedMembers}`)
  fetchRepresentatedMembers!: () => Promise<RegistrationResponse[]>;
  @Action(`${SV_NAMESPACE}/${SVAction.GetAvailableVotes}`)
  getAvailableVotes!: (questionId: string) => Promise<number | void>;

  @Getter(`${SV_NAMESPACE}/${SVGetter.LiveQuestion}`)
  activeQuestion!: SVQuestion | null;
  @Getter(`${GEMA_NAMESPACE}/${GemaGetter.Member}`)
  member!: RegistrationResponse;
  @Getter(`${GEMA_NAMESPACE}/${GemaGetter.RepresentatedMembers}`)
  reps!: RegistrationResponse[];
  @Getter("userInfo", { namespace: USER_NAMESPACE })
  userInfo!: UserInfo;

  @Watch("activeQuestion", { immediate: true })
  onActiveQuestion(question: SVQuestion | null) {
    if (question) {
      this.getAvailableVotes(question.id);
    }
  }

  loading = true;
  voteSuccess = false;
  voteError = false;

  async created() {
    this.getActiveQuestion();

    this.prepareCrypto();

    await this.fetchMe()
      .then(() => this.fetchRepresentatedMembers())
      .finally(() => {
        this.loading = false;
      });
  }

  get representedUsersFiltered() {
    const res = this.reps.filter(
      (reg) =>
        !reg.registration?.representation?.noVotingPermission &&
        reg.registration?.status === RegistrationStatus.CONFIRMED
    );
    res.push(this.member);
    return res;
  }

  submit(payload: SVVotePayload[]) {
    this.submitVote(payload)
      .then(() => {
        this.voteSuccess = true;
      })
      .catch(() => {
        this.voteError = true;
      });
  }
}
