import { RegistrationResponse } from "@/panel/gema/types";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NavigationState {
  navbarItems: NavigationItem[];
  registration?: RegistrationResponse;
}

export interface NavigationResponse {
  id: string;
  tabs: NavigationItem[];
  tenantId: string;
  broadcastId: string | null;
}

export interface NavigationItem {
  icon: string;
  name: NavigationId;
  rank: number;
}

export enum NavigationId {
  CHAT = "CHAT",
  MERCH = "MERCH",
  POLLS = "POLLS",
  QA = "QA",
  WORTMELDUNG = "WORTMELDUNG",
  RESOURCES = "RESOURCES",
}
