import { Spect8Chat } from "@vaudience/spect8-chat-ui-vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MerchPage from "../merch/components/MerchPage.vue";
import { NavigationId } from "../navigation/types";
import ResourcesPage from "../resources/components/ResourcesPage.vue";
import Polls from "../views/Polls.vue";

import QAPage from "@/panel/qa/components/QAPage.vue";
import GemaQAPanel from "@/panel/qa/components/gema/GemaQAPanel.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/chat",
    name: NavigationId.CHAT,
    component: Spect8Chat,
  },
  {
    path: "/merch",
    name: NavigationId.MERCH,
    component: MerchPage,
  },
  {
    path: "/polls",
    name: NavigationId.POLLS,
    component: Polls,
  },
  {
    path: "/resources",
    name: NavigationId.RESOURCES,
    component: ResourcesPage,
  },
  {
    path: "/qa",
    name: NavigationId.QA,
    component: QAPage,
  },
  {
    path: "/wortmeldung",
    name: NavigationId.WORTMELDUNG,
    component: GemaQAPanel,
  },
];

const router = new VueRouter({
  mode: "abstract",
  routes: routes,
});

export default router;
