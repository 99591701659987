import { GeneralSettings } from "./../types";
import Vue from "vue";
import { MutationTree } from "vuex";
import { AppConfig, Config, ConfigState, Options } from "../types";

const setInitialized = (state: ConfigState) => {
  if (state.initialized) return;

  const initialized =
    state.appConfig && state.broadcastConfig && state.tenantConfig;

  if (initialized) {
    Vue.set(state, "initialized", true);
  }
};

export const mutations: MutationTree<ConfigState> = {
  appConfig(state, appConfig: AppConfig) {
    Vue.set(state, "appConfig", appConfig);
    setInitialized(state);
  },
  broadcastConfig(state, broadcastConfig: Config) {
    Vue.set(state, "broadcastConfig", broadcastConfig);
    setInitialized(state);
  },
  config(state, config: Config) {
    Vue.set(state, "broadcastConfig", config);
    Vue.set(state, "tenantConfig", config);
    setInitialized(state);
  },
  tenantConfig(state, tenantConfig: Config) {
    Vue.set(state, "tenantConfig", tenantConfig);
    setInitialized(state);
  },
  options(state, options: Options) {
    Vue.set(state, "options", { ...state.options, ...options });
  },
  agenda(state, agenda: string) {
    state.agenda = agenda;
  },
  tenantName(state, tenantName: string) {
    state.tenantName = tenantName;
  },
  settings(state, settings: GeneralSettings) {
    Vue.set(state, "settings", settings);
  },
};
