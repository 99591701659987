

























































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class ResourceInfoGema extends Vue {
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() bpTitle!: string;
  @Prop() bpTitleTwo!: string;
  @Prop() bpSubtitle!: string;
  @Prop() bpSubtitleTwo!: string;
  @Prop() cardTitle!: string;
  @Prop() cardTitleTwo!: string;
  @Prop() cardLink!: string;
  @Prop() cardLinkTwo!: string;
  @Prop() htmlContent!: string;

  downloadLink(link: string): string {
    return link;
  }
}
