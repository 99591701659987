import { AppRootState } from "@/shared/@types/app";
import { GetterTree } from "vuex";
import { Role, UserInfo, UserState } from "../types";

export const getters: GetterTree<UserState, AppRootState> = {
  created(state): boolean {
    return state.created;
  },
  hasUpdateError(state): boolean {
    return state.hasUpdateError;
  },
  isAdmin(state): boolean {
    return state.userInfo?.roles?.includes(Role.ROLE_ADMIN) ?? false;
  },
  isModerator(state): boolean {
    return state.userInfo?.roles?.includes(Role.ROLE_MODERATOR) ?? false;
  },
  userInfo(state): UserInfo | undefined {
    return state.userInfo;
  },
  userInfoIsValid(state): boolean {
    return state.userInfo?.displayName !== null;
  },
};
