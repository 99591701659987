import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { PollState } from "../types";
import { PanelRootState } from "@/panel/store/PanelRootState";

export const state: PollState = {
  isLoading: true,
  polls: [],
  pollVotes: {},
};

export const POLL_NAMESPACE = "poll";

const namespaced = true;

export const poll: Module<PollState, PanelRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
