import { PanelRootState } from "@/panel/store/PanelRootState";
import { Getter } from "vuex";
import { getResourceFilename } from "../helpers";
import { Resource, ResourceGetter, ResourceState } from "../types";

function sortByFilenameAsc(a: Resource, b: Resource): number {
  const filenameA = getResourceFilename(a);
  const filenameB = getResourceFilename(b);

  if (filenameA < filenameB) return -1;
  if (filenameA > filenameB) return 1;

  return 0;
}

export const getters: Record<
  ResourceGetter,
  Getter<ResourceState, PanelRootState>
> = {
  [ResourceGetter.BroadcastResources](state) {
    return state.broadcastResources.sort(sortByFilenameAsc);
  },
  [ResourceGetter.TenantResources](state) {
    return state.tenantResources.sort(sortByFilenameAsc);
  },
  [ResourceGetter.AllResources](state): Resource[] {
    return [...state.tenantResources, ...state.broadcastResources].sort(
      sortByFilenameAsc
    );
  },
  [ResourceGetter.ResourceCount](state): number {
    return state.tenantResources.length + state.broadcastResources.length;
  },
};
