import { MutationTree } from "vuex";
import Vue from "vue";
import { NchanState } from "../types";
import { NchanService } from "../nchan-service";

export const mutations: MutationTree<NchanState> = {
  setClients(state, clients: NchanService[]) {
    Vue.set(state, "clients", clients);
  },
};
