import { AppRootState } from "@/shared/@types/app";
import { GetterTree } from "vuex";
import { NchanService } from "../nchan-service";
import { NchanState } from "../types";

export const getters: GetterTree<NchanState, AppRootState> = {
  client(state): NchanService[] | undefined {
    return state.clients;
  },
};
