




import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { isGema } from "@/shared/utils";
import { Vue, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import PollsPage from "../polls/components/PollsPage.vue";
import SecureVoting from "../secure-voting/SecureVoting.vue";

type ComponentTypes = "PollsPage" | "SecureVoting";

@Component({
  components: {
    PollsPage,
    SecureVoting,
  },
})
export default class Polls extends Vue {
  @Getter(`${CONFIG_NAMESPACE}/tenantName`) tenantName!: string;

  get componentName(): ComponentTypes {
    if (isGema(this.tenantName)) return "SecureVoting";
    return "PollsPage";
  }
}
