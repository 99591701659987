














































import { Component } from "vue-property-decorator";
import DefaultPoll from "./DefaultPoll.vue";
import PollImageAnswers from "./shared-components/PollImageAnswers.vue";
import PollImageResults from "./shared-components/PollImageResults.vue";
import PollQuestion from "./shared-components/PollQuestion.vue";

@Component({
  components: {
    PollQuestion,
    PollImageAnswers,
    PollImageResults,
  },
})
export default class ImagePoll extends DefaultPoll {}
