









































import { Vue, Component } from "vue-property-decorator";
import PollCreation from "./PollCreation.vue";
import { Action, Getter } from "vuex-class";
import { POLL_NAMESPACE } from "../store";
import { USER_NAMESPACE } from "@/shared/user/store";
import { Poll } from "@/panel/polls/types";
import { PollAction } from "../types";
import PollItem from "@/panel/polls/components/PollItem.vue";

@Component({
  components: {
    PollCreation,
    PollItem,
  },
})
export default class PollsPage extends Vue {
  @Getter("isAdmin", { namespace: USER_NAMESPACE })
  readonly isAdmin!: boolean;
  @Getter("isModerator", { namespace: USER_NAMESPACE })
  readonly isModerator!: boolean;
  @Getter("userInfoIsValid", { namespace: USER_NAMESPACE })
  readonly userInfoIsValid!: boolean;

  @Getter("isLoading", { namespace: POLL_NAMESPACE })
  readonly isLoading!: Poll[];
  @Getter("polls", { namespace: POLL_NAMESPACE })
  readonly polls!: Poll[];

  @Action(PollAction.FETCH_POLLS, { namespace: POLL_NAMESPACE })
  fetchPolls!: () => Promise<void>;

  pollCreationVisible = false;

  get canCreate(): boolean {
    return (this.isAdmin || this.isModerator) && this.userInfoIsValid;
  }

  created() {
    this.fetchPolls();
  }
}
