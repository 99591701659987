/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import axios from "axios";
import { generateRandomId } from "../utils/RandomId";
import { HitType, TrackingParameter } from "./types";
import VueRouter from "vue-router";

class TrackingService {
  overlaySessionId = "";
  router: VueRouter;
  sessionId: string;

  constructor(router: VueRouter) {
    this.router = router;
    this.sessionId = generateRandomId();
  }

  logEvent(params: any): void {
    params = { ...params };
    if (params[TrackingParameter.HIT_TYPE] === undefined) {
      params[TrackingParameter.HIT_TYPE] = HitType.EVENT;
    }

    let resetOverlaySessionId = false;
    if (
      params[TrackingParameter.EVENT_ACTION] === "toggle" &&
      params[TrackingParameter.EVENT_LABEL] === "open"
    ) {
      this.overlaySessionId = generateRandomId();
    } else if (
      params[TrackingParameter.EVENT_ACTION] === "toggle" &&
      params[TrackingParameter.EVENT_LABEL] === "closed"
    ) {
      resetOverlaySessionId = true;
    }

    const pathInfo: any = this.getPathInfo();
    params = { ...pathInfo, ...params };

    this.log(params).then(() => {
      if (resetOverlaySessionId) {
        this.overlaySessionId = "";
      }
    });
  }

  private getPathInfo(): any {
    const location: any = window.location;
    const pathInfo: any = {};
    pathInfo[TrackingParameter.DOCUMENT_PATH] = this.router.currentRoute.path;
    pathInfo[TrackingParameter.DOCUMENT_TITLE] = this.router.currentRoute.name;
    if (location.hostname !== undefined) {
      pathInfo[TrackingParameter.DOCUMENT_HOST] = location.hostname;
    }
    if (location.href !== undefined) {
      pathInfo[TrackingParameter.DOCUMENT_LOCATION] = location.href;
    }
    return pathInfo;
  }

  private log(params: any): Promise<void> {
    params[TrackingParameter.APP_TYPE] = "WEB";
    params[TrackingParameter.TIMESTAMP] = Date.now();
    params[TrackingParameter.SESSION_ID] = this.sessionId;
    if (this.overlaySessionId !== "") {
      params[TrackingParameter.OVERLAY_SESSION_ID] = this.overlaySessionId;
    }

    for (const key in TrackingParameter) {
      if (
        params[TrackingParameter[key as keyof typeof TrackingParameter]] ===
        undefined
      ) {
        params[TrackingParameter[key as keyof typeof TrackingParameter]] = null;
      }
    }

    if (process.env.VUE_APP_TRACKING_TYPE === "log") {
      Vue.$log.debug("Tracking", params);
      return new Promise((resolve) => resolve());
    }
    return axios.post(process.env.VUE_APP_TRACKING_URL, JSON.stringify(params));
  }
}

let trackingService: TrackingService;

function initTrackingService(router: VueRouter): void {
  if (!trackingService) {
    trackingService = new TrackingService(router);
  }
}

export { trackingService, initTrackingService };
