import { MutationTree } from "vuex";
import Vue from "vue";
import { Role, User, UserInfo, UserState } from "../types";
import { getImageMimeType } from "@/shared/utils/MimeType";

export function toUserInfo(user: User): UserInfo {
  const userInfo: UserInfo = {
    avatar: {
      src: null,
      mimeType: null,
    },
    badge: {
      src: null,
      mimeType: null,
    },
    color: null,
    displayName: null,
    firstName: null,
    userId: user.id,
    lastName: null,
    roles: [Role.ROLE_USER],
    username: user.username,
  };
  if (user.editRequest !== null) {
    userInfo.displayName = user.editRequest.displayName ?? null;
    userInfo.color = user.editRequest.color ?? null;
    if (user.editRequest.avatar?.src) {
      userInfo.avatar = {
        src: user.editRequest.avatar.src,
        mimeType: getImageMimeType(user.editRequest.avatar.src),
      };
    }
    if (user.editRequest.badge?.src) {
      userInfo.badge = {
        src: user.editRequest.badge?.src,
        mimeType: getImageMimeType(user.editRequest.badge.src),
      };
    }
  }
  if (user.roles?.length > 0) {
    userInfo.roles = user.roles;
  }
  return userInfo;
}

export const mutations: MutationTree<UserState> = {
  setUser(state, user: User) {
    Vue.set(state, "user", user);
    if (user.editRequest !== null) {
      Vue.set(state, "created", true);
    }
    Vue.set(state, "userInfo", toUserInfo(user));
  },
  setUpdateError(state, updateError: boolean) {
    state.hasUpdateError = updateError;
  },
};
