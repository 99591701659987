import { ActionTree } from "vuex";
import { MerchState } from "../types";
import { PanelRootState } from "@/panel/store/PanelRootState";
import { apiService } from "@/shared/services/api-service";

export const actions: ActionTree<MerchState, PanelRootState> = {
  async getCategories(context): Promise<void> {
    try {
      await apiService.getShopCategories().then((categories) => {
        context.commit("setCategories", categories);
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  async getShopItems(context, categoryId: string): Promise<void> {
    try {
      await apiService
        .getShopItemsByCategoryId(categoryId)
        .then((shopItems) => {
          context.commit("setShopItems", shopItems);
        });
    } catch (error) {
      throw new Error(error);
    }
  },
  async getDisclaimers(context): Promise<void> {
    try {
      await apiService.getShopDisclaimers().then((disclaimers) => {
        context.commit("setDisclaimers", disclaimers);
      });
    } catch (error) {
      throw new Error(error);
    }
  },
};
