





























































































import {
  CreatePollDto,
  NewPollAnswerDto,
  PollLocation,
} from "@/panel/polls/types";
import { Vue, Component, Emit } from "vue-property-decorator";
import { Action } from "vuex-class";
import { POLL_NAMESPACE } from "../store";
import { PollAction } from "../types";

@Component({})
export default class PollCreation extends Vue {
  @Action(PollAction.CREATE_POLL, { namespace: POLL_NAMESPACE })
  createPollAction!: (data: CreatePollDto) => Promise<void>;

  durations = [
    { label: this.$t("polls.minutes", { num: 5 }).toString(), value: 5 },
    { label: this.$t("polls.minutes", { num: 10 }).toString(), value: 10 },
    { label: this.$t("polls.minutes", { num: 20 }).toString(), value: 20 },
    { label: this.$t("polls.minutes", { num: 30 }).toString(), value: 30 },
    { label: this.$t("polls.minutes", { num: 40 }).toString(), value: 40 },
  ];

  locationOptions = Object.values(PollLocation);

  createInProgress = false;

  newPollData = this.getNewPollData(this.durations[0]);

  getNewPollData(duration: { label: string; value: number }): CreatePollDto {
    return {
      question: "",
      answers: [this.newPollAnswer(), this.newPollAnswer()],
      duration: duration.value,
      startDate: "",
      endDate: "",
      tenantId: "",
      createdById: "",
      messageRouterPayload: {
        tenantWide: false,
        broadcastIds: [],
        channelIds: [],
      },
      immediate: true,
      location: PollLocation.DEFAULT,
    };
  }

  newPollAnswer(): NewPollAnswerDto {
    return {
      text: "",
      image: null,
    };
  }

  get disabled() {
    return (
      this.newPollData.question.length < 5 ||
      this.newPollData.question.length > 100 ||
      this.newPollData.answers.some((answer) => answer.text === "") ||
      this.createInProgress
    );
  }

  get addAnswerIsDisabled(): boolean {
    return this.newPollData.answers.length >= 8;
  }

  addAnswer(): void {
    this.newPollData.answers.push({
      text: "",
      image: null,
    });
  }

  createPoll(): void {
    this.createInProgress = true;
    this.createPollAction(this.newPollData).then(() => {
      this.createInProgress = false;
      this.close();
    });
  }

  @Emit()
  close(): boolean {
    return true;
  }

  pollLocationLabel(location: PollLocation): string {
    switch (location) {
      case PollLocation.DEFAULT:
        return this.$t("polls.locationDefault").toString();
      case PollLocation.INLINE:
        return this.$t("polls.locationInline").toString();
    }
  }
}
