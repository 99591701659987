import { pollsService } from "@/panel/polls/services/polls-service";
import { CreatePollDto, Poll, PollStatus, PollVote } from "@/panel/polls/types";
import { PanelRootState } from "@/panel/store/PanelRootState";
import { CONFIG_NAMESPACE } from "@/shared/config/store";
import { AppConfig } from "@/shared/config/types";
import { USER_NAMESPACE } from "@/shared/user/store";
import { UserInfo } from "@/shared/user/types";
import Vue from "vue";
import { Action, ActionContext } from "vuex";
import { PollAction, PollState, PollVoteActionPayload } from "../types";

const fetchPolls = async (
  context: ActionContext<PollState, PanelRootState>
) => {
  try {
    context.commit("setLoading", true);

    const { tenantId, broadcastId }: AppConfig = context.rootGetters[
      CONFIG_NAMESPACE + "/appConfig"
    ];

    if (!tenantId || !broadcastId) {
      throw new Error("Could not fetch polls, missing tenantId or broadcastId");
    }

    const polls = await pollsService.getPollsByBroadcastIds(tenantId, [
      broadcastId,
    ]);

    Vue.$log.debug("setPolls", polls);
    context.commit("setPolls", polls);

    context.commit("setLoading", false);
  } catch (error) {
    Vue.$log.debug("Poll fetch error", error);

    context.dispatch(
      "tracking/logError",
      {
        ea: "error",
        ec: "poll",
        ed: error,
      },
      { root: true }
    );
  }
};

const getUsersVotes = async (
  context: ActionContext<PollState, PanelRootState>,
  pollId: string
): Promise<void> => {
  try {
    const userInfo: UserInfo | undefined =
      context.rootGetters[USER_NAMESPACE + "/userInfo"];

    if (!userInfo || !userInfo.userId) {
      throw new Error("Missing user id");
    }

    const userVotes = await pollsService.getPollVotesByPollIdAndUserId(
      pollId,
      userInfo.userId
    );

    context.commit("setUserVotes", {
      pollId,
      userVotes,
    });
  } catch (error) {
    Vue.$log.debug("getUserVotes error", error);
  }
};

const endPoll = async (
  context: ActionContext<PollState, PanelRootState>,
  poll: Poll
): Promise<void> => {
  context.commit("updatePoll", {
    ...poll,
    status: PollStatus.ENDED,
  });
};

const startPoll = async (
  context: ActionContext<PollState, PanelRootState>,
  poll: Poll
): Promise<void> => {
  context.commit("startPoll", poll);
};

const createPoll = async (
  context: ActionContext<PollState, PanelRootState>,
  pollData: CreatePollDto
): Promise<void> => {
  try {
    const userInfo: UserInfo | undefined =
      context.rootGetters[USER_NAMESPACE + "/userInfo"];
    const appConfig: AppConfig =
      context.rootGetters[CONFIG_NAMESPACE + "/appConfig"];

    if (!appConfig || !userInfo) {
      throw new Error("Missing appConfig or userInfo");
    }

    const poll: CreatePollDto = {
      ...pollData,
      startDate: new Date().toISOString(),
      endDate: new Date(
        Date.now() + 60 * 1000 * pollData.duration
      ).toISOString(),
      tenantId: appConfig.tenantId,
      messageRouterPayload: {
        ...pollData.messageRouterPayload,
        broadcastIds: [appConfig.broadcastId],
      },
      createdById: userInfo.userId,
      //   createdById: "ceeb3531-407a-49ff-abc5-c6894fb4da06"
    };

    await pollsService.createPoll(poll);
  } catch (error) {
    Vue.$log.debug("createPoll error", error);
    throw error;
  }
};

const vote = async (
  context: ActionContext<PollState, PanelRootState>,
  { pollId, pollVote }: PollVoteActionPayload
): Promise<void> => {
  try {
    const userInfo: UserInfo | undefined =
      context.rootGetters[USER_NAMESPACE + "/userInfo"];

    if (!userInfo || !userInfo.userId) {
      throw new Error("Missing user Id");
    }

    const userVotes: PollVote[] = await pollsService.saveVote({
      id: "",
      pollId,
      pollAnswer: pollVote,
      userId: userInfo.userId,
      date: new Date().toISOString(),
    });

    context.commit("setUserVotes", {
      pollId,
      userVotes,
    });
  } catch (error) {
    Vue.$log.debug("Poll vote error", error);
    context.dispatch(
      "tracking/logError",
      {
        ea: "error",
        ec: "pollvote",
        ed: error,
      },
      { root: true }
    );
  }
};

const deletePoll = async (
  context: ActionContext<PollState, PanelRootState>,
  poll: Poll
): Promise<void> => {
  context.commit("deletePoll", poll);
};

const updatePoll = async (
  context: ActionContext<PollState, PanelRootState>,
  poll: Poll
): Promise<void> => {
  context.commit("updatePoll", poll);
};

const toggleResultDisplay = async (
  context: ActionContext<PollState, PanelRootState>,
  pollId: string
): Promise<void> => {
  context.commit("togglePollResults", pollId);
};

const fetchPollById = async (
  { commit }: ActionContext<PollState, unknown>,
  pollId: string
): Promise<void> => {
  const poll = await pollsService.getPollById(pollId);
  commit("updatePoll", poll);
};

export const actions: Record<
  Exclude<PollAction, PollAction.POLL_RESULTS>,
  Action<PollState, PanelRootState>
> = {
  [PollAction.CREATE_POLL]: createPoll,
  [PollAction.FETCH_POLLS]: fetchPolls,
  [PollAction.GET_USERS_VOTES]: getUsersVotes,
  [PollAction.END_POLL]: endPoll,
  [PollAction.VOTE]: vote,
  [PollAction.POLL_VOTED]: updatePoll,
  [PollAction.POLL_PUBLISH_RESULTS]: updatePoll,
  [PollAction.POLL_STARTED]: startPoll,
  [PollAction.POLL_DELETED]: deletePoll,
  [PollAction.POLL_ENDED]: endPoll,
  [PollAction.POLL_MODERATOR_TOGGLE_RESULTS]: toggleResultDisplay,
  [PollAction.GET_POLL]: fetchPollById,
};
